import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { firebaseConfig } from './constants';


const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
// if (typeof window !== "undefined") {
//   if (firebase.messaging.isSupported()) {
//   }
// }

export const requestForToken = () => new Promise((resolve) => {
    return getToken(messaging, { vapidKey: "BLrxSXnUD0-2-X04uJJ6o9CNagNJtKmkhxMZOEgSzu4YBSvke4kBtQU9xibB_I8UUURie2lkm_-gB55YpDqUOvY" })
      .then((currentToken) => {
        if (currentToken) {
          console.log('current token for client: ', currentToken);
          resolve(currentToken)
          // Perform any other neccessary action with the token
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        resolve("err")
      });
  });
  
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload)
      resolve(payload);
    });
  });