import React, { useEffect, useState } from "react";
import styles from "./UserTopBar.module.scss";
import { compose } from "../../utils/common";
import { useDispatch, useSelector, connect } from "react-redux";
import { useLocation } from "react-router";
import { ReactComponent as UserAvatarWhite } from "../../images/UserIcon.svg";
import { ReactComponent as UserAvatarDark } from "../../images/UserIconMono.svg";
import { ReactComponent as BoothBookingIcon } from "../../images/BoothBookingIcon.svg";
import IconButton from "../../components/UI/IconButton/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import ReactMomentCountDown from "react-moment-countdown";
import TopBarContext from "../../utils/TopBarContext";
import { default as MuiBtn } from '@material-ui/core/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { default as Dropdown2 } from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from "react-bootstrap/Button";
import { logout } from "../../store/user/actions";
import PrivilegedComponent from "../PrivilegedComponent/PrivilegedComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons/faUserPlus";
import UserTopBarExhibitorList from "./UserTopBarExhibitorList";
import { matchPath, useHistory, withRouter } from "react-router";
import { formatter } from "../../utils/datetime";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import DeleteIcon from '@material-ui/icons/Delete';
import Home from "@material-ui/icons/Home";
import ListIcon from '@material-ui/icons/List';
import SearchIcon from '@material-ui/icons/Search';
import PaymentIcon from '@material-ui/icons/Payment';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import BoothTypeBadge from "../UI/BoothTypeBadge/BoothTypeBadge";
import { ExhSelectorGetCurrent } from "../../store/exhibitor/selectors";
import withAppModal from "../../HOCs/withAppModal";
import withCart from "../../HOCs/withCart";
import withToast from "../../HOCs/withToast";
import AppsIcon from '@material-ui/icons/Apps';
import WidgetsIcon from '@material-ui/icons/Widgets';
import DialpadIcon from '@material-ui/icons/Dialpad';
import mapValues from "lodash/mapValues";
import LoadingAnimationPage from "../../components/UI/LoadingAnimationPage/LoadingAnimationPage";
import {
  BookingActionAddToCart,
  // BookingActionAddToCart,
  BookingActionClearCart,
  BookingActionFetchCart,
  BookingActionRemoveFromCart
} from "../../store/booking/actions";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { confirmAlert } from "react-confirm-alert";
import NotificationMenu from "./NotificationMenu";
import classNames from "classnames";
import { Alert } from "@material-ui/lab";

const scrollItem = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "*::-webkit-scrollbar": {
          width: "10px"
        },
        "*::-webkit-scrollbar-track": {
          background: "#E4EFEF"
        },
        "*::-webkit-scrollbar-thumb": {
          background: "#1D388F61",
          borderRadius: "2px"
        }
      }
    }
  }
});

const DefaultUserAvatar = {
  light: UserAvatarWhite,
  dark: UserAvatarDark
};

const UserTopBar = compose(
  withRouter,
  withAppModal,
  withToast
)(({
    toastSuccess,
    ...props
  }) => {
  const userObj = useSelector(state => state.user.obj),
    carts = useSelector(state => {
      // console.log("state", state)
      return state.booking ? state.booking.cart : false
    }),
    events = useSelector(state => state.event.list),
    currentExhibitor = useSelector(state => ExhSelectorGetCurrent(state)),
    boothType = useSelector(state => state.boothType),
    location = useLocation(),
    dispatch = useDispatch(),
    history = useHistory(),
    MenuTopBar = React.forwardRef(({ onClick }, ref) => (
      <div className={styles.listItem} 
        ref={ref}
        onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}><DialpadIcon style={{fontSize: "32px"}}/></div>
    ));
    const eventIdFromUrl = location.pathname.replace("/event/", "") * 1;
    const cart = Object.values(carts)?.find(c => c.event_id === eventIdFromUrl)
    // console.log(cart)
    const event = events[eventIdFromUrl * 1] || {};
    // console.log(event)

  const [show, setShow] = useState(false),
        [station, setStation] = useState(false),
        [exhibitorStatus, defineExhibitorStatus] = React.useState(currentExhibitor?.newestStatus?.value);
  const toggle = () => {

  }
  const msg =
    `We're reviewing your exhibitor application. Take a look around and we'll send you an email as soon as you're approved (or not).`

  const CartButton = React.forwardRef(({ onClick }, ref) => {
    const length = cart?.booths?.length ? cart?.booths?.length : 0
    return (
      <div
        style={{ marginLeft: `2rem`, cursor: "pointer" }}
        ref={ref}
        onClick={(e) => {
          e.preventDefault()
          onClick(e)
        }}>
        <ShoppingCartIcon style={{ color: "#333" }} />
        {length > 0 && <span style={{
          backgroundColor: "#e1297a",
          color: "white",
          width: 16,
          height: 16,
          position: "absolute",
          fontSize: 11,
          borderRadius: 10,
          textAlign: "center",
          right: -11,
          top: -6,
        }}>{length}</span>}
      </div>
      // <Button
      //   color="primary"
      //   style={{ marginLeft: `2rem` }}
      //   ref={ref}
      //   onClick={(e) => {
      //     e.preventDefault()
      //     onClick(e)
      //   }}
      // >
      //   <strong>
      //     <ShoppingCartIcon />&ensp;
      //     {length}{` `}
      //     Item{length > 1 ? `s` : ``}
      //   </strong>
      // </Button>
    )
  })

  useEffect(() => {
    // console.log(location)
    if (currentExhibitor && currentExhibitor?.categories?.length < 1 && !location.pathname?.includes("exhibitor/")) {
      confirmAlert({
        closeOnClickOutside: false,
        title: "Action required!",
        message: "Please select your exhibitor's category and declare what you will be selling.",
        buttons:[
            {
                label: 'Go to Exhibitor Details',
                onClick: () => history.push("/exhibitor/detail/?tab=0")
            }
        ]
      });
    }
  })
  
  const DeleteFromCartIcon = ({ id }) => (
    <IconButton
      style={{ margin: "-.5rem -.5rem -.3rem .5rem", transform: 'scale(.75)' }}
      size={"small"}
      onClick={() => {
            setStation(true)
            const booth_no = cart?.booths?.find(
              item => item.id === id
            ).booth_no
            dispatch(BookingActionRemoveFromCart(event.id, id))
              .then(() => {
                toastSuccess(<>Removed <strong>{booth_no}</strong> from cart!</>)
                setStation(false)
              }
              )
      }}
      tooltip={{ title: "Delete item from cart", placement: "bottom" }}
    >
      <ClearIcon />
    </IconButton>
  )

  const RemoveAllFromCart = () => {
    setStation(true)
    const cartItemIds = cart?.booths?.map(booth => booth.id)
    dispatch(BookingActionClearCart(event.id, cartItemIds))
    .then(() => {
      toastSuccess("Successfully emptied cart!")
      setStation(false)
    })
    .catch((err) => console.log(err))
  }
  
  const _bookNow = () => {
    const cart_id = cart.id
    if (typeof cart_id === `undefined`)
      if (process.env.NODE_ENV === `development`)
        throw new Error(`Something went wrong when do booking`)
      else console.error(`Something went wrong when do booking`)
    // props.navigateToModal(`/payment/detail/cart/${cart_id}`)
    history.push(`/checkout/cart/${cart_id}`)
  }
  const eventId = event?.id;
  
  const CheckoutButton = () => {
    defineExhibitorStatus(currentExhibitor.newestStatus?.value)
    let isDisabled = false
    if (typeof exhibitorStatus === `undefined`
        || exhibitorStatus * 1 < 3
        || typeof cart?.id === `undefined`
        || cart?.booths?.length < 1) {
      isDisabled = true
    }
    return (
      <MuiBtn
        variant={"primary"}
        className={"w-100 text-uppercase"}
        disabled={isDisabled}
        onClick={() => _bookNow()}
      >
        Checkout
      </MuiBtn>
    )
  }

  const reloadCart = (t) => {
    if (t < 1000) {
      setTimeout(() => {
        confirmAlert({
          closeOnClickOutside: false,
          title: "",
          message: "You have exceeded the time limit and your reservation has been released. Please refresh your screen.",
          buttons:[
              {
                  label: 'Refresh',
                  onClick: () => window.location.reload()
              }
          ]
      });
        // dispatch(BookingActionFetchCart());
      }, 1000)
    }
  }

  if (!userObj) {
    return (
      <TopBarContext.Consumer>
        {({ theme }) => {
          return (
            <div
              className={`d-flex flex-row-reverse align-items-center top-bar-theme-${theme}`}
            > 
              <Button style={{ marginLeft: `1.3rem`, fontSize: 11, padding: "5px 15px" }}>
                <Link style={{color: "white", textDecoration: "none"}} to={"event"}>BOOK A BOOTH</Link>
              </Button>
              {/* <div className={styles.searchIcon}>
                { show && <input className={styles.searchItemInput}/>}
                <SearchIcon onClick={() => setShow(!show)} className={styles.searchItemIcon}/>
              </div> */}
            </div>
          )
        }}
      </TopBarContext.Consumer>
    ) 
  }

  useEffect(() => {
    dispatch(BookingActionFetchCart());
  }, [currentExhibitor])

  return (
    <TopBarContext.Consumer>
      {({ theme }) => {
        const DefaultUser = DefaultUserAvatar[theme]

        return (
          <div
            className={`d-flex flex-row-reverse align-items-center top-bar-theme-${theme}`}
          >
            <Dropdown>
              <Dropdown.Toggle as={MenuTopBar}/>

              <Dropdown.Menu align={`right`} style={{top: "160%", right: "4px", boxShadow: "0px 0px 5px 0px #00000040" }}>
                <div className={styles.menuSelector}>
                  <div className={styles.menuSelectorItem}>
                    {/* <div>
                      <Link style={{textDecoration: "none"}} className={styles.ItemLink} to={"/payment/manage/booking"}>
                        <span className={styles.ItemIcon}><PaymentIcon/></span>
                        <span className={styles.iconInfomation}>My Payments</span>
                      </Link>
                    </div> */}
                    <div>
                      <Link className={styles.ItemLink} style={{textDecoration: "none"}} to={"/payment/manage/booking"}>
                       <span className={styles.ItemIcon}><LocalAtmIcon/></span>
                       <span className={styles.iconInfomation} onClick={toggle}>My Bookings</span>
                      </Link>                     
                    </div>
                    <div className={styles.ItemSelector}>
                      <Link className={styles.ItemLink} style={{textDecoration: "none"}} to={"/exhibitor/detail/"}>
                        <span className={styles.ItemIcon}><AccountBalanceIcon/></span>
                        <span className={styles.iconInfomation} onClick={toggle}>Exhibitor Details</span>
                      </Link>
                    </div>
                    
                  </div>
                  <div className={styles.menuSelectorItem}>
                   
                    <div>
                      <Link className={styles.ItemLink} style={{textDecoration: "none"}} to={"/user/profile"}>
                       <span className={styles.ItemIcon}><AccountBoxIcon/></span>
                       <span className={styles.iconInfomation} onClick={toggle}>My Account</span>
                      </Link>
                    </div>
                    <div className={styles.ItemSelector}>
                      <span className={styles.ItemIcon}><ExitToAppIcon/></span>
                      <span className={styles.iconInfomation} onClick={() => dispatch(logout())}>Log out</span>
                    </div>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <div className={styles.Icon}>
              {/* <DefaultUser id={"UserTopBar__avatar"} /> */}
              <img height={40} width={40} style={{ borderRadius: 20 }} src={userObj?.profile_photo || '/user.jpeg'} alt="" />
            </div>
              <NotificationMenu className="ml-2" mobile={false} />
              {(!location.pathname.match(`^/event/\\d+$`)) && (
                <Button style={{ marginLeft: `1.3rem`, fontSize: 12, padding: "5px 15px" }}>
                  <Link
                    style={{color: "white", textDecoration: "none", fontWeight: 'bold' }} 
                    to={ "/event" }
                  >
                    BOOK A BOOTH
                  </Link>
                </Button>
              )}
              
              {(location.pathname.match(`^/event/\\d+$`)) && (
                <Dropdown>
                  <Dropdown.Toggle as={CartButton} />
                  <Dropdown.Menu align={'left'} className={classNames(styles.DropdownCartML, styles.DropdownCart)}>
                    <div>
                      <Dropdown2.Header className={`${styles.DropdownCartEventName} d-flex justify-content-between`}>
                        <div>
                          <strong>{event?.name}</strong>
                        </div>
                        {(cart?.booths?.length > 0) && (
                          <div>
                            <ins
                              onClick={() => RemoveAllFromCart()}
                              className={styles.DropdownClearCart}
                            >Clear</ins>
                          </div>
                        )}
                      </Dropdown2.Header>
                      <Dropdown2.Divider className={styles.DropdownCartDivider} />
                      <div className={styles.boxContent}>
                        {(!cart || cart?.booths?.length === 0) && <Alert className="mx-4 mt-1 mb-1" severity="info">No items in cart.</Alert>}
                        {(cart?.booths?.length > 0) && cart?.booths?.map((item, index) => (
                            <div className={`d-flex justify-content-between ${styles.DropdownCartItem}`}>
                              <div>
                                {/* {console.log("cart",item)} */}
                                <span className={styles.DropdownCartItemName}>{item.booth_name} - {item.booth_type_name || boothType.booth.map[item.booth_type_id]?.name} {item.booth_name}</span>
                                <br />
                                <BoothTypeBadge
                                  id={item.booth_type_id}
                                  bgColor={boothType.booth.map[item.booth_type_id]?.color
                                    || item.booth_type_color}
                                  name={item.booth_type_name
                                    || boothType.booth.map[item.booth_type_id]?.name}
                                  isSmall={true}
                                />
                              </div>
                              <div className={`d-flex align-items-center ${styles.DropdownCartItemPricing}`}>
                                <div>{formatter.format(item.snapshot_price)}</div>
                                <DeleteFromCartIcon id={item.id} />
                              </div>
                            </div>
                        ))}
                      </div>
                      <div className={`mb-4`}></div>
                      <div className={styles.DropdownCartButton}>
                        <CheckoutButton />
                      </div>
                      {cart?.booths?.length > 0 && (
                        <div className={styles.DropdownCartTimeExpiried}>
                          <div className={styles.DropdownCartText}>
                            <ReactMomentCountDown
                                toDate={new Date(cart.expiry_time)}
                                // toDate={new Date("2022-12-18 02:56:00")}
                                // onCountDownEnd={() => console.log("ends up. Do something???")}
                                targetFormatMask={"m"}
                              />{' '}mins{' '} 
                              <ReactMomentCountDown
                                toDate={new Date(cart.expiry_time)}
                                onTick={(t) => reloadCart(t)}
                                // toDate={new Date("2022-12-18 02:56:00")}
                                // onCountDownEnd={() => reloadCart()}
                                targetFormatMask={"s"}
                              /> seconds left before cart is automatically emptied. 
                          </div>
                        </div>
                      )}
                      <LoadingAnimationPage loading={station} />
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            {/* <div className={styles.searchIcon}>
              { show && <input className={styles.searchItemInput}/>}
              <SearchIcon onClick={() => setShow(!show)} className={styles.searchItemIcon}/>
            </div> */}
          </div>
        )
      }}
    </TopBarContext.Consumer>
  )
})

export default UserTopBar

export const InternalUserTopBar = () => {
  const userObj = useSelector(state => state.user.obj),
        dispatch = useDispatch(),
        history = useHistory();
  
  const UserTopBar = React.forwardRef(({ onClick }, ref) => (
    <div
      className={styles.Icon}
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      <img height={50} width={50} style={{ borderRadius: 25 }} src={userObj?.profile_photo || '/user.jpeg'} alt="" />
    </div>
  ))
  
  if (!userObj) return null;
  return (
    <div
      className={`d-flex flex-row-reverse align-items-center top-bar-theme-light`}
    >
      <Dropdown2>
        <Dropdown2.Toggle as={UserTopBar} />
        <Dropdown2.Menu align={'right'} className={styles.Dropdown}>
          <div className={styles.DropdownBg}>
            <Dropdown2.Header className={styles.DropdownHeader}>
              <strong>
                {userObj.first_name} {userObj.last_name}
              </strong>
              <div>{userObj.email}</div>
            </Dropdown2.Header>
            <Dropdown2.Divider />
            {/* <Dropdown2.Item href="#/Profile" align={'end'}>Profile</Dropdown2.Item> */}
            <Dropdown2.Item className={`d-flex align-items-end ${styles.DropdownItem}`} onClick={() => dispatch(logout())}>
              <ExitToAppIcon />&ensp;Logout
            </Dropdown2.Item>
          </div>
        </Dropdown2.Menu>
      </Dropdown2>
      <div style={{marginRight: `1rem`}}>
        <strong>{userObj.first_name} {userObj.last_name}</strong>
      </div>
      <span className={`text-primary`} style={{marginRight: `1rem`}}>|</span>
      <DragIndicatorIcon className={styles.AdminTools} />
      <NotificationsIcon className={styles.AdminTools} />
      <Home
        className={styles.AdminTools}
        onClick={() => {
          history.push("/")
        }}
      />
      {/* <PersonAddIcon
        className={styles.AdminTools}
        onClick={() => {
          history.push("/internal/register")
        }}
      /> */}
    </div>
  )
}