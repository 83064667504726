import React from "react";
import "../../App.scss";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './Footer.module.scss'

// @withTranslation()
const Footer = props => {
  return (null
    // <Grid>
    //   <Grid className={styles.FooterContainer}>
    //     <small className={styles.ContainerItem}>
    //       {`Copyright ©${new Date().getFullYear()} Adi Media Pte Ltd. All rights reserved.`}
    //     </small>
    //   </Grid>
    // </Grid>
  );
};

export default Footer;

export const InternalFooter = () => {
  return (null
    // <div className={`AppFooter`}>
    //   {/* <Row>
    //     <Col xl={12} xs={12}>
    //       <small className="text-grey">
    //         Copyright ©{new Date().getFullYear()} Adi Media Pte Ltd. All rights reserved.
    //       </small>
    //     </Col>
    //   </Row> */}
    // </div>
  )
}