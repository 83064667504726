import React from "react";
import loadable from "@loadable/component";
const DatePicker = loadable(() =>
  import(/* webpackChunkName: "library" */ "./src")
);

const DateTimePicker = ({ onChange, ...props }) => (
  <DatePicker onChange={value => onChange({ target: { value } })} {...props} />
);

export default DateTimePicker;
