import React from "react";
import styles from "./Terms.module.scss";
const TermsContent = () => {
  return (
    <p className={styles.span}>
<p>&nbsp;</p>
<p>Last updated: 16/12/2022</p>
<h5 style={{ textAlign: "center" }}><strong>OVERVIEW</strong></h5>
<p><strong>&nbsp;</strong></p>
<p>ADI MEDIA PTE LTD (hereinafter "Organiser") operates this website. ADI MEDIA PTE LTD offers this website, including all information, tools, and services available from this site, to you, the user, conditioned upon your acceptance of all terms, conditions, policies, and notices stated here.</p>
<p>&nbsp;</p>
<p>Any new features or tools added to the current website will be subject to the Terms of Contract. The Organiser reserves the right to update, change, or replace any part of these by posting changes to its website. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol>
<li><strong> DEFINITIONS</strong></li>
</ol>
<p>&nbsp;</p>
<ul>
<li>The term &lsquo;Exhibitor&rsquo; shall mean any company, partnership, firm, or individual applicant for a Booth in the Exhibition and shall include all its employees, servants, and agents.</li>
<li>The term &lsquo;Exhibition&rsquo; shall refer to the event(s) that the Exhibitor is applying for.</li>
<li>The term &lsquo;Organiser&rsquo; shall mean: ADI MEDIA PTE LTD, whose registered office is located at 230 Victoria Street Level 15, Bugis Junction Towers Singapore 188024.</li>
<li>The term &lsquo;Booth&rsquo; includes a shell-scheme booth and raw space.</li>
<li>The term &lsquo;Online Portal&rsquo; shall refer to the page where the Exhibitor can book for the Exhibition and make an online payment for the application.</li>
<li>The term &lsquo;Registration Data&rsquo; shall mean all information and data that the Exhibitor provides in the registration system, including, but shall not be limited to, personal data such as name or business name, and contact information specifically residential or business address, email address, mobile or telephone number, nationality, gender, date of birth, marital status, photographs, and other audio-visual data, employment information, and financial data such as credit card numbers, debit card numbers, or bank account information.</li>
</ul>
<p><span>&nbsp;</span></p>
<p>By signing in to the online portal and or applying for a Booth in the Exhibition, the Exhibitor agrees to be bound by the following terms and conditions.</p>
<p>&nbsp;</p>
<p><strong>&nbsp;</strong></p>
<ol start="2">
<li><strong> APPLICATION FOR BOOTH</strong></li>
</ol>
<p>&nbsp;</p>
<ul>
<li>Application for the Exhibition shall be made only through the website&rsquo;s prescribed Online Portal, QOOEE in particular. The use of the Application Form is no longer necessary and the Organiser has ceased using it.</li>
<li>In order to successfully book for the Exhibition, the Exhibitor will be required to sign in to the Online Portal by creating an account and password that can be obtained by completing the Organiser&rsquo;s online registration form, which requests certain information and data (&lsquo;Registration Data&rsquo;). By registering, the Exhibitor agrees that all information provided in the Registration Data is true and accurate.</li>
<li>The internet booking system is directly linked to the reservation software, and the Organiser may use email, messaging, or chat services to communicate with the Exhibitor regarding the booking status and inform any upcoming events or announcements.</li>
<li>Successful online bookings will receive an automatic confirmation via email. No further confirmation is required.</li>
<li>Full payment is required of the fees to complete an application.</li>
<li>The Organiser reserves the right to accept or reject any application without giving any reasons.</li>
</ul>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol start="3">
<li><strong> PAYMENT POLICY</strong></li>
</ol>
<p>&nbsp;</p>
<ul>
<li>The Exhibitor shall strictly follow the payment terms of the Organiser. Upon confirmation of the booking, the Exhibitor is required to give full payment of the outstanding fees and or rates.</li>
<li>Except upon the sole determination of the Organiser based on the Exhibitor&rsquo;s credit standing as can be readily gleaned from the system, the Exhibitor may be allowed to pay on an installment basis the outstanding balance subject to the schedule provided by the Organiser.</li>
<li>Failure and or refusal to pay on time by the Exhibitor will result in cancellation of the Exhibitor&rsquo;s Booth allocation at the Organiser&rsquo;s discretion, and any payment made, if any, will be forfeited in favor of the Organiser.</li>
<li>Any crediting over <span>of</span> payment from a separate exhibition is not accepted as payment for the Exhibition.</li>
</ul>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol start="4">
<li><strong>ALLOCATION OF BOOTH</strong></li>
</ol>
<p>&nbsp;</p>
<ul>
<li>The Exhibitor may inform the Organiser of their preferred choice of Booth(s) upon booking through the Online Portal. This is not considered a right or entitlement to the Booth(s).</li>
<li>The Organiser has no obligation to allocate Booth(s) according to the specified preference.</li>
<li>Booth allocations shall be confirmed only upon the Organiser&rsquo;s notification to the Exhibitor.</li>
<li>The Organiser reserves the right to re-allocate the Exhibitor Booth(s) at any time in its sole discretion if deemed in the best interest of the Exhibition as a whole.</li>
<li>At any time prior to the set-up of the Exhibition and/or at any time during the Exhibition, the Organiser reserves the right to:
<ul>
<li>change the Booth allocated to the Exhibitor;</li>
<li>alter the size, dimensions, and layout plan of the Booth; and</li>
<li>undertake any other structural alterations as the Organiser deems fit.</li>
</ul>
</li>
<li>The Exhibitor shall have no claim for damages as a result of these changes.</li>
<li>Exhibitors do not have any right to transfer, exchange or sell any Booth(s) without the Organiser&rsquo;s approval.</li>
</ul>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol start="5">
<li><strong>CANCELLATION OF BOOTH(S)</strong></li>
</ol>
<p>&nbsp;</p>
<ul>
<li>If the Exhibitor intends to cancel participation, the Exhibitor shall promptly notify the Organiser.</li>
<li>No refund will be given upon cancellation.</li>
<li>The Organiser reserves all rights to claim from the Exhibitor for all losses, damages, and expenses incurred due to the cancellation by the Exhibitor.</li>
</ul>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol start="6">
<li><strong>CHANGE OF BOOTH</strong></li>
</ol>
<p>&nbsp;</p>
<ul>
<li>If a request is made to change Booth(s) and the price of the requested Booth(s) is higher, the Exhibitor shall incur the difference in price and pay the balance amount according to the prescribed deadline.</li>
<li>If the price is lower, there shall be no refund for the difference in price.</li>
</ul>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol start="7">
<li><strong>EXHIBITS</strong></li>
</ol>
<p>&nbsp;</p>
<ul>
<li>The Exhibitor agrees that the exhibits are required to be suitable for the nature and purpose of the Exhibition.</li>
<li>The following goods falling within, but not limited to any of the categories listed below, are not allowed for the exhibition:
<ul>
<li>Illicit drugs or other legally prohibited goods;</li>
<li>Medical devices such as contact lenses;</li>
<li>Flammable, explosive or radioactive materials;</li>
<li>Any goods infringing on or likely to infringe upon the intellectual property rights of others;</li>
<li>Goods likely to conflict with or violate any laws, regulatory requirements, or codes applicable in Singapore;</li>
<li>Any goods that the Organiser, at its sole discretion, believes may diminish the quality or success of the Exhibition.</li>
</ul>
</li>
<li>The Exhibitor represents and warrants that it will not bring in any of the goods falling within subsections 7.2(a) to 7.2(f) above.</li>
</ul>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol start="8">
<li><strong>USE OF BOOTH</strong></li>
</ol>
<p>&nbsp;</p>
<ul>
<li>The Exhibitor is required to exhibit the goods as declared in their Application <span>Form</span> and to staff the Booth with competent personnel during the opening hours of the Exhibition.</li>
<li>The Exhibitor shall not paint or otherwise alter the floors, ceilings, pillars, or walls without the prior approval of the Organiser.</li>
<li>The Exhibitor agrees that it shall not use any drills, screws, paints, nails, staples, or affix any fixtures of any kind onto the partitions, floors, ceilings, or the aluminium frames and existing panels of the Booth. The partitions, floors, ceiling, aluminium frames, and panels of the Booth are collectively referred to as the &ldquo;Booth Structure&rdquo;.&nbsp;</li>
<li>The Exhibitor shall indemnify and hold harmless the Organiser from any and all liability arising from any damage to the Booth Structure and the walls, floors, or any other part of the exhibition hall within the area allocated to the Exhibitor (including any area in which goods and/or Exhibitors&rsquo; exhibits are placed), whether caused by the Exhibitor, by any person employed or engaged on the Exhibitor&rsquo;s behalf, or by a visitor to the Booth.</li>
<li>The Organiser reserves the right to refuse admittance to any visitor to the Exhibition or to bar access to the Booth.</li>
</ul>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol start="9">
<li><strong>CHANGES TO VENUE, DATE AND DURATION</strong></li>
</ol>
<p>&nbsp;</p>
<ul>
<li>The Organiser reserves the right to change the venue, date and/or duration of the Exhibition. The Organiser shall take reasonable measures to keep the Exhibitor informed of these changes.</li>
<li>In the event of any change of venue, date and/or duration of the Exhibition, this agreement shall remain in force.</li>
<li>The Organiser also reserves the right to cancel the Exhibition in exceptional circumstances. In the event of such a cancellation of the Exhibition by the Organiser, the Exhibitor shall not be entitled to any claims for compensation in connection with their bookings for participation.</li>
</ul>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol start="10">
<li><strong>IMMIGRATION PROCEDURES</strong></li>
</ol>
<p><strong>&nbsp;</strong></p>
<ul>
<li>The Exhibitor shall comply with all immigration laws, rules and regulations in Singapore.</li>
<li>The Organiser shall not be responsible for the Exhibitor&rsquo;s compliance with immigration laws, rules and regulations or related fees involved in obtaining permission for entry into Singapore for the purpose of participation at the Exhibition.</li>
<li>If the Exhibitor is unable to participate in the Exhibition due to a rejection of permission for entry into Singapore, the Exhibitor must pay the Organiser a cancellation fee as follows:</li>
<li>Cancellation 8 weeks prior to Exhibition date: 30% of rental cost</li>
<li>Cancellation 4 weeks prior to Exhibition date: 50% of rental cost</li>
<li>Cancellation less than 4 weeks prior to Exhibition date: 100% of rental cost</li>
</ul>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol start="11">
<li><strong>PROHIBITION</strong></li>
</ol>
<p>&nbsp;</p>
<p>The Exhibitor agrees that it will not do any of the acts set forth below:</p>
<ul>
<li>To assign, sell, sub-lease or offer as a security, the position or rights of the Exhibitor in this agreement, in whole or in part, without the prior written consent of the Organiser;</li>
<li>To post or display a signboard, notice board, or advertising sign, inside, outside, or around the Exhibition, except in a place designated by the Organiser. This subsection does not apply to cases where the Organiser&rsquo;s or Venue Owner&rsquo;s prior consent has been obtained;</li>
<li>To bring in any cumbersome, obtrusive or other offensive articles that cause or are likely to cause nuisance, annoyance or discomfort to other persons due to, inter alia (including but not limited to), dirt, dust, unpleasant odours, loud sounds and noise;</li>
<li>Any act which may cause damage to the exhibition hall, including the Booth;</li>
<li>Stay overnight in the space within the Booth or elsewhere of the Exhibition venue;</li>
<li>Explain the goods or promote products through a megaphone or other sound amplification device. However, the Organiser reserves the right to make exceptions to this subsection;</li>
<li>Any acts not allowed under the terms of this agreement; and</li>
<li>Any acts which violate any laws, regulatory requirements, or codes applicable in Singapore.</li>
</ul>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol start="12">
<li><strong>DECORATION AND SETTING UP OF STALL</strong></li>
</ol>
<p>&nbsp;</p>
<ul>
<li>All Exhibitors shall use only the Official Contractor appointed by the Organiser for electrical and lighting installations;</li>
<li>All Exhibitors must complete their Booth set-up and decorations 2 hours before the opening of the event;</li>
<li>The decorations used by each Exhibitor should not interfere with the Booth set-up and decorations of other Exhibitors;</li>
<li>No facilities or signs are allowed in the passages to the exhibition hall;</li>
<li>Any decorations shall be less than 1.8 metres in height. However, the Exhibitor may apply for a special exemption from the Organiser;</li>
<li>All decorations must be kept within the confines of the Booth; and</li>
<li>The Organiser may order the Exhibitor to discontinue or alter construction work or to take any other measures to maintain safety, to be done at the Exhibitor&rsquo;s expense.</li>
</ul>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol start="13">
<li><strong>PASSAGEWAY</strong></li>
</ol>
<p>&nbsp;</p>
<p>The passageway between and around the Booths shall not be used by the Exhibitor for any display or sale of goods. The Exhibitor shall not at any time block or obstruct the said passageway in any manner and shall ensure that the passageway is free at all times for visitors to use.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol start="14">
<li><strong>MOVEMENT OF EXHIBITS</strong></li>
</ol>
<p>&nbsp;</p>
<ul>
<li>The Exhibitor shall bear the responsibility and expenses for the transport of exhibits to the exhibition venue;</li>
<li>The Exhibitor shall make its own arrangements for the storage and warehousing of its exhibits;</li>
<li>The Exhibitor shall remove all exhibits from the exhibition hall within the period stipulated by the Organiser and shall fully indemnify and hold the Organiser harmless against any loss caused by reason of the Exhibitor&rsquo;s delay or damage to the exhibition hall.</li>
</ul>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol start="15">
<li><strong>LIGHTING AND ELECTRICAL WORKS</strong></li>
</ol>
<p>&nbsp;</p>
<ul>
<li>The Exhibitor shall employ only the Official Contractor to handle all lighting and electrical works. Exhibitors must order additional fittings and electrical works if their requirements exceed the standard lighting and electrical works provided by the Official Contractor.</li>
<li>The Organiser reserves the right to charge double the stipulated rates for electrical installations and Booths that are not submitted within the stipulated time limit.</li>
<li>The Exhibitor agrees that electrical supplies and services are not guaranteed for Booths that are not completed within the stipulated time limit.</li>
</ul>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol start="16">
<li><strong>FORCE MAJEURE</strong></li>
</ol>
<p>&nbsp;</p>
<p>The Exhibitor agrees that the Organiser shall not be liable for any loss sustained by the Exhibitor, which is directly or indirectly attributable to the cancellation, postponement, suspension or reduction of the scheduled Exhibition due to an event of force majeure, including but not limited to:&nbsp;</p>
<ol>
<li>Acts of war, military action, terrorism, criminal activity or other civil disturbance;</li>
<li>Legal statute or requisition by civil authority;</li>
<li>Fire, explosion, collapse or any other disaster or cataclysmic event, whether natural or human-caused;</li>
<li>Strikes, lockouts or other employer/worker disputes;</li>
<li>Failure of electricity, water, sewage or other essential utilities and services;</li>
<li>Government-mandated or recommended cancellation of any public gathering owing to a medical epidemic (e.g. SARS, COVID-19, etc.) or other danger to public health; or</li>
<li>Any other event that may prevent the use of the exhibition hall or threaten the safety, operation or enjoyment of the Exhibition.</li>
</ol>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol start="17">
<li><strong>SECURITY</strong></li>
</ol>
<p>&nbsp;</p>
<p>The Organiser shall take reasonable security precautions in the interest of the Exhibitors and visitors. However, the Organiser shall not be held responsible for any loss or theft of Exhibits in the Exhibition during the build-up to period of the Exhibition and dismantling period. The Organiser shall also not be responsible for any loss or damage to exhibits or any articles belonging to the Exhibitor during the same period.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol start="18">
<li><strong>FIRE REGULATIONS</strong></li>
</ol>
<p>&nbsp;</p>
<ul>
<li>All materials used in the construction of the Booth and during the Exhibition must be properly fire-proofed in accordance with local regulations. Fire marshals will patrol the exhibition hall and are authorized to limit any demonstrations that are potential fire hazards.</li>
<li>Gas cylinders, naked lights, petrol, kerosene, and other dangerous, explosives or highly flammable substances are prohibited from the exhibition halls at all times. Exhibitors are advised to use a hotplate, electrical oven, or microwave for cooking demonstrations.</li>
</ul>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol start="19">
<li><strong>CUSTOMS AND EXCISE REGULATIONS</strong></li>
</ol>
<p>&nbsp;</p>
<ul>
<li>Only duty-paid goods and goods which do not violate any laws, regulatory requirements, or codes applicable in Singapore are allowed for sale during the Exhibition.</li>
<li>The Exhibitor shall comply with the Singapore Customs &amp; Excise Regulations when importing its goods and/or exhibits and shall be solely liable for all fines and penalties incurred as a result of non-compliance with the Singapore Customs and Excise Regulations.</li>
<li>The Organiser shall not be held responsible in the event of any loss incurred by the Exhibitor, arising out of, or in connection with the Exhibitor&rsquo;s importation and/or sale of goods for the purposes of the exhibition.</li>
</ul>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol start="20">
<li><strong>ENFORCEMENT</strong></li>
</ol>
<p>&nbsp;</p>
<p>The Organiser reserves the right to bar admission to any company or person, and/or to compel any company or person to leave the Exhibition without assigning any reason whatsoever, regardless of whether the company or person has signed this agreement or is otherwise known to the Organiser.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol start="21">
<li><strong>SINGAPORE FOOD AGENCY (SFA) REGULATIONS</strong></li>
</ol>
<p>&nbsp;</p>
<ul>
<li>The Exhibitor shall adhere to the laws, rules, and regulations prescribed by the Singapore Food Agency and the Singapore Food Agency Act 2019, which include the following (not an exhaustive list):
<ul>
<li>Where proper supporting facilities are not available, only pre-packed/pre-cooked food obtained from licensed sources may be sold.</li>
<li>Food on display is to be placed in proper showcases and properly covered.</li>
<li>Only pre-packed ice cream obtained from permitted sources may be distributed or sold.</li>
<li>Food handlers shall at all times practise a high standard of food and personal hygiene. Where it is not practical to use utensils such as tongs to handle cooked food, disposable gloves must be worn.</li>
<li>Food handlers must be registered with SFA. All food handlers must attend the Basic Food Hygiene Course conducted by WSQ‑accredited training organisations.</li>
</ul>
</li>
<li>The Exhibitor shall submit the relevant documents to the Organiser as required.</li>
</ul>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol start="22">
<li><strong>INDEMNITY, LIABILITY AND RISKS</strong></li>
</ol>
<p>&nbsp;</p>
<ul>
<li>The Exhibitor shall fully and effectively indemnify and hold the Organiser harmless from and against all claims, demands, actions, suits, proceedings, orders, damages, costs, losses, and expenses of any nature, arising out of, or in connection with (i) the Exhibitor&rsquo;s installation, removal and maintenance work; (ii) the Exhibitor&rsquo;s occupancy and use of the Booth; (iii) the exhibits in the Booth; (iv) the Exhibitor&rsquo;s presence in the exhibition hall or a part thereof; (v) any loss or injury to any third party, howsoever caused, who is attending or visiting or examining or passing by the Exhibitor&rsquo;s Booth during the Exhibition; or (vi) any occurrence at the Booth howsoever caused.</li>
<li>The Exhibitor shall be liable for the acts and omissions of its employees and agents.</li>
<li>The Exhibitor is advised to fully insure all exhibits and goods at its Booth during the Exhibition. The Organiser will not be held liable for any damages or losses suffered by the Exhibitor during the period of the Exhibition.</li>
</ul>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol start="23">
<li><strong>MAJOR PROMOTION ACTIVITIES</strong></li>
</ol>
<p>&nbsp;</p>
<ul>
<li>The Exhibitor must obtain prior approval from the Organiser for all large/major sales promotions during the Exhibition.</li>
<li>The Organiser reserves the right:
<ul>
<li>To sanction or stop any sales promotion should such a promotion cause disciplinary problems or inconvenience to the general public and other Exhibitors; and</li>
<li>To bill the Exhibitor for any additional costs incurred as a result of the Exhibitor&rsquo;s promotional activities, i.e. personnel, barricades, etc.</li>
</ul>
</li>
</ul>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol start="24">
<li><strong>AMENDMENTS</strong></li>
</ol>
<p>&nbsp;</p>
<p>The Organiser may amend, update or vary the terms of the contract from time to time at its own discretion, and the latest version of the Terms of Contract shall be the only Terms in effect for all existing and new applications. All Notices included within the Online Portal are also in effect.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol start="25">
<li><strong>GOVERNING LAW</strong></li>
</ol>
<p>&nbsp;</p>
<p>The terms of this agreement shall be governed by and construed in accordance with the laws of Singapore. Any dispute arising under or in connection with this agreement shall be subject to the exclusive jurisdiction of the courts of Singapore to which the parties to this agreement hereby submit.</p>
<p>&nbsp;</p>
    </p>
  );
}

export default TermsContent;
