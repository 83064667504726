import React from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { EXHIBITOR_ACTION_FETCH } from "../store/exhibitor/const";
import withAppModal from "./withAppModal";
import ExhibitorAddConsent from "../fragments/ExhibitorAddConsent/ExhibitorAddConsent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons/faUserPlus";
import Button from "react-bootstrap/Button";
import { compose } from "../utils/common";
import { Route, Switch, useHistory } from "react-router";
import { ExhSelectorGetCurrent } from "../store/exhibitor/selectors";
import { isMobile } from "react-device-detect";
function aware(user) {
  const userId = user?.id;
  if (typeof userId === "undefined")
    return -1;
  return userId;
}

const ensureInner = Component =>
  connect(state => ({
    _userObj: state.user.obj,
    _exhibitors: state.exhibitor
  }))(({ _userObj, _exhibitors, dispatch, navigateToModal, ...props }) => {
    const history = useHistory();
    return (
      <Switch>
        <Route path={"/exhibitor/add"}>
          <Component {...props} />
        </Route>
        <Route
          path={"*"}
          render={() => {
            const $addExhibitor = (
              <ExhibitorAddConsent>
                <Button
                  onClick={() =>
                    (!isMobile ? navigateToModal : history.push)(
                      "/exhibitor/add"
                    )
                  }
                >
                  <FontAwesomeIcon icon={faUserPlus} className={"mr-3"} />
                  Add new exhibitor
                </Button>
              </ExhibitorAddConsent>
            );
            // console.log(_userObj);
            const userId = aware(_userObj);
            // if (typeof userId === "undefined") return null;
            const selectId = _exhibitors.select[userId];
            if (typeof selectId !== "number") return $addExhibitor;
            if (isEmpty(_exhibitors[userId])) return $addExhibitor;
            const exh = _exhibitors[userId].find(
              exh => exh.id * 1 === selectId * 1
            );

            if (typeof exh === "undefined" || exh === null) {
              dispatch({ type: EXHIBITOR_ACTION_FETCH });
              return $addExhibitor;
            } else return <Component {...props} />;
          }}
        />
      </Switch>
    );
  });

const withEnsureCurrentExhibitor = Component =>
  compose(
    withAppModal,
    ensureInner,
    connect(state => ({ currentExhibitor: ExhSelectorGetCurrent(state) }))
  )(Component);

export default withEnsureCurrentExhibitor;
