import React from "react";
import * as PropTypes from "prop-types";
import styles from "./ResetPassword.module.scss";
import Form from "react-bootstrap/Form";
import PasswordInput from "../../components/UI/PasswordInput/PasswordInput";
import withSimpleForm from "../../utils/SimpleForm";
import { withRouter } from "react-router";
import { appValidators, createValidator, withToken } from "../../utils/form";
import PageTitle from "../../components/UI/PageTitle/PageTitle";
import { Link } from "react-router-dom";
import { axiosApi } from "../../utils/api";
import { withTopBarContext } from "../../utils/TopBarContext";
import { ReactComponent as QooeeLogo } from "../../images/QooeeLogo.svg";

import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import classnames from "classnames"
// eslint-disable-next-line no-unused-vars
const bgTokenExpired = require("../../images/bg-token-expired.jpg");
const bgRegisterFormExt = require("../../images/bg-register-form-ext.jpg");

@withTopBarContext({ isFullscreen: true })
@withRouter
@withToken
@withSimpleForm({
  entries: props => ({
    token: props.token.token,
    password: "",
    confirmPassword: ""
  })
})
class ResetPassword extends React.Component {
  /**
   * @private
   * @type {SimpleReactValidator}
   */
  _validator;
  constructor(props) {
    super(props);

    this._validator = createValidator(this, {
      validators: {
        password: appValidators.password
      }
    });
  }

  /**
   * @private
   * */
  async doResetPassword() {
    
    const search = new URLSearchParams(this.props.location.search);
      let tokenFromURL = search.get("token");
      tokenFromURL = tokenFromURL.replace(/\s/g, "+");

    const { data } = await axiosApi.put(
      "/v1/u/reset-password",
      {
        ...this.props.form,
        token: tokenFromURL
      }
    );
    // console.log(data);
    if (data.success) {
      this.props.history.push(
        `${this.props.isAdmin ? "/internal" : ""}/login?email=${
          data.user.email
        }`
      );
    }
  }

  /**
   * @private
   * */
  renderBody = () => {
    const self = this;
    if (self.props.token.validating) return null;
    if (self.props.token.error)
      return (
        <Container fixed className={styles.Form}>
          <Grid container spacing={0}>
            <Grid item xs style={{ position: "relative" }}>
              <Link to={"/"}>
                <QooeeLogo className={styles.Logo} />
              </Link>
              <img src={bgTokenExpired} alt={"Qooee"} className={"w-100"} />
            </Grid>
            <Grid>
              <Form style={{ padding: "5rem", paddingBottom: "0" }}>
                <Form.Group>
                  <h1 className={"mb-1"} style={{ fontWeight: "600" }}>
                    {"Token error."}
                  </h1>
                  <h5 className={"text-grey mt-4"} style={{ marginBottom: 20 }}>
                    Your password recovery link has expired!
                  </h5>
                </Form.Group>

                <Form.Group>
                  <Form.Text className={classnames("mt-4 mb-4")}>
                    <Link to={"/login"} className={"no-link"}>
                      <h6 className={"href-link"}>
                        <u>Return to QOOEE login</u>
                      </h6>
                    </Link>
                  </Form.Text>
                </Form.Group>
              </Form>
            </Grid>
          </Grid>
        </Container>
      );
    return (
      <Container fixed className={styles.Form}>
        <Grid container spacing={0}>
          <Grid item xs style={{ position: "relative" }}>
            <Link to={"/"}>
              <QooeeLogo className={styles.Logo} />
            </Link>
            <img src={bgRegisterFormExt} alt={"Qooee"} className={"w-100"} />
          </Grid>
          <Grid>
            <Form style={{ padding: "5rem", paddingBottom: "0" }}>
              <Form.Group>
                <h1 className={"mb-1"} style={{ fontWeight: "600" }}>
                  {"Reset password."}
                </h1>
                <h5 className={"text-grey"} style={{ marginBottom: 60 }}>
                  Enter your new password below.
                </h5>
              </Form.Group>
              <Form.Group>
                <PasswordInput
                  placeholder={"Password"}
                  size={"lg"}
                  {...self.props.formBinding("password")}
                >
                  {self._validator.message(
                    "Password",
                    self.props.form.password,
                    "required|password"
                  )}
                </PasswordInput>
              </Form.Group>
              <Form.Group>
                <PasswordInput
                  size={"lg"}
                  label={"Confirm Password"}
                  placeholder={"Confirm Password"}
                  {...self.props.formBinding("confirmPassword")}
                >
                  {[
                    self._validator.message(
                      "Confirm Password",
                      self.props.form.confirmPassword,
                      "required|password"
                    ),
                    self._validator.message(
                      "",
                      self.props.form.confirmPassword ===
                        self.props.form.password,
                      "accepted",
                      {
                        messages: {
                          default: "Confirm password doesn't match password."
                        }
                      }
                    )
                  ]}
                </PasswordInput>
              </Form.Group>
              <Form.Group>
                <div className="d-flex flex-row-reverse">
                  <Button
                    variant={"primary"}
                    size={"lg"}
                    className={"align-self-end w-100"}
                    style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
                    onClick={() => {
                      if (!self._validator.allValid()) {
                        self._validator.showMessages();
                        return;
                      }
                      // noinspection JSIgnoredPromiseFromCall
                      self.doResetPassword();
                    }}
                  >
                    Reset my password
                  </Button>
                </div>
              </Form.Group>
            </Form>
          </Grid>
        </Grid>
      </Container>
    );
  };

  render() {
    return (
      <div className={styles.ResetPasswordPage}>
        <div>{this.renderBody()}</div>        
      </div>
    );
  }
}

ResetPassword.propTypes = {
  isAdmin: PropTypes.bool
};
ResetPassword.defaultProps = {
  isAdmin: false
};

export default ResetPassword;
