import React from "react";
import styles from "./AlertDismissable.module.scss";
import Alert from "react-bootstrap/Alert";
// import { hot } from "react-hot-loader";

const AlertDismissable = ({
  variant = `danger`, heading = ``, dismissable = false, children, ...props
}) => {
  const [show, setShow] = React.useState(true);
  if (show) {
    return (
      <Alert
        className={styles.AlertDismissible}
        variant={variant}
        onClose={dismissable ? () => setShow(false) : {}}
        dismissible={dismissable}
        {...props}
      >
        {heading.length > 0 && <Alert.Heading>{heading}</Alert.Heading>}
        <p style={{ margin: 0 }}>{children}</p>
      </Alert>
    );
  }
  return <div />;
}

// export default AlertDismissible;
export default AlertDismissable;
