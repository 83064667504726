import React from "react";
import "./App.scss";
import TopBar from "./components/TopBar/TopBar";
import styles from "./App.module.scss";
import TopBarContext, { TopBarContextProvider } from "./utils/TopBarContext";
import { Switch, withRouter, useLocation, useHistory } from "react-router";
import routes, { dialogs, modal, modalHistory } from "./pages/routes";
import Modal from "react-bootstrap/Modal";
import qs from "qs";
import { connect } from "react-redux";
import LoadingAnimationPage from "./components/UI/LoadingAnimationPage/LoadingAnimationPage";
import { createTheme } from "@material-ui/core/styles";
import { makeStyles, ThemeProvider } from "@material-ui/styles";
import withToast, { ToastNotifier } from "./HOCs/withToast";
import { MemoryRouter } from "react-router";
import withAppModal, { AppModalContext } from "./HOCs/withAppModal";
import fragmentRoutes from "./fragments/routes";
import classnames from "classnames";
import { MobileAppPortalContext } from "./components/MobileAppFixedPortal/MobileAppFixedPortal";
import { isMobile } from "react-device-detect";
import Drawer from "@material-ui/core/Drawer";
import Responsive from "./components/UI/Responsive/Responsive";
import Footer from "./components/Footer/Footer";
import { Container, Link } from "@material-ui/core";
import Zendesk from "react-zendesk";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { onMessageListener, requestForToken } from "firebase";
// import "./firebase-messaging-sw.js"
import { SnackbarProvider } from 'notistack';
import Notification from "components/Notification/Notification";

const ZENDESK_KEY = "26fdbbdb-da47-40f0-8128-ac68b6f94d67";
const setting = {
  color: {
    theme: "#e10f76"
  },
  launcher: {
    chatLabel: {
      "en-US": "Need Help"
    }
  },
  contactForm: {
    fields: [
      { id: "description", prefill: { "*": "" } }
    ]
  }
};

const theme = createTheme({
  typography: {
    fontFamily: [
      "Roboto",
      // "DM+Sans",
      "Montserrat",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "Noto Sans",
      "sans-serif",
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol",
      "Noto Color Emoji"
    ]
  },
  palette: {
    primary: {
      main: "#e10f76"
    },
    secondary: {
      main: "#414042"
    },
    action: {
      disabled: undefined,
      disabledBackground: undefined
    }
  },
  overrides: {
    MuiFab: {
      root: {
        "&$disabled": {
          boxShadow: undefined,
          backgroundColor: undefined,
          color: undefined,
          opacity: 0.45,
          zIndex: 99
        }
      }
    },
    // MuiTab: {
    //   textColorPrimary: {
    //     "&$disabled": {
    //       color: undefined,
    //     }
    //   }
    // }
  }
});

function ModalDesktop({ show, onHide, size, children }) {
  return (
    <Modal show={show} onHide={onHide} size={size} backdrop={"static"}>
      {children}
    </Modal>
  );
}
const useModalMobileStyles = makeStyles({
  paper: {
    borderTopLeftRadius: "1rem",
    borderTopRightRadius: "1rem"
  }
});
function ModalMobile({ show, onHide, children }) {
  const classes = useModalMobileStyles();
  return (
    <Drawer
      classes={classes}
      anchor={"bottom"}
      open={show}
      onClose={() => onHide()}
    >
      {children}
    </Drawer>
  );
}

function ModalHandler({ dialog, navigateToModal, parentRouter }) {
  let history = useHistory(),
    location = useLocation();

  React.useEffect(() => {
    // console.log(dialog);
    history.push(dialog);
  }, [dialog, history]);

  return (
    <AppModalContext.Provider
      value={{ parentRouter, close: () => navigateToModal() }}
    >
      <Responsive
        desktop={ModalDesktop}
        mobile={ModalMobile}
        show={location.pathname !== "/"}
        size={"xl"}
        onHide={() => navigateToModal()}
      >
        <React.Suspense fallback={<span />}>
          <Switch>{dialogs}</Switch>
        </React.Suspense>
      </Responsive>
    </AppModalContext.Provider>
  );
}


@withRouter
@connect(state => ({
  loading: state.app.loading,
  userRole: state.user.obj?.role,
}))
@withAppModal
class App extends React.Component {
  /**
   *
   * @type {React.RefObject<unknown>}
   * @private
   */
  _$app = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      topBarContext: {
        theme: "light",
        RightButton: () => <div />,
        setRightButton: this.setRightButton
      },
      modal: "",
      dialog: "/"
    };
  }

  getSnapshotBeforeUpdate(prevProps) {
    const locationChanged =
      this.props.location.pathname !== prevProps.location.pathname;
    if (locationChanged) {
      this.state.topBarContext.setRightButton(() => <></>);
    }

    // const topBarContext = { ...this.state.topBarContext };
    // if (this.props.location.pathname.startsWith("/internal"))
    //   topBarContext.theme = "dark";
    // else topBarContext.theme = "light";
    // this.setState({ topBarContext });

    return null;
  }

  static getDerivedStateFromProps(props, state) {
    // handle modal query
    const search = new URLSearchParams(props.location.search);
    let modal = "";
    if (search.get("modal")) modal = search.get("modal");
    let dialog = "/";
    if (search.get("dialog")) dialog = search.get("dialog");
    return { modal, dialog };
  }

  // just for eliminate react warning

  // async componentDidMount() {
  //   this.messageListener = messaging().onMessage(async message => console.log("message received!!!")
  // }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentDidMount() {
    // requestForToken();
  }

  setRightButton = renderFn => {
    let topBarContext = Object.assign({}, this.state.topBarContext);
    topBarContext.RightButton = renderFn;
    this.setState({ topBarContext });
  };

  /**
   * Just remove modal query
   */
  handleAppModalClose = () => {
    const query = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    for (let key of Object.getOwnPropertyNames(query))
      if (/modal/.test(key)) {
        delete query[key];
      }
    const newRoute =
      this.props.location.pathname +
      qs.stringify(query, { addQueryPrefix: true });
    // console.log(newRoute);
    this.props.history.push(newRoute);
  };
  renderAppModal = () => {
    if (this.state.modal.length === 0) return;
    const ModalComponent = modal[this.state.modal];
    if (typeof ModalComponent === "undefined")
      throw new Error(`Modal "${this.state.modal}" not found!`);

    const _modalProps = qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true
      }),
      modalProps = {};
    for (let _key in _modalProps)
      if (_key.length > 5) {
        let key = _key.substr(5);
        key = key.charAt(0).toLowerCase() + key.slice(1);
        modalProps[key] = _modalProps[_key];
      }

    return (
      <Modal
        show={true}
        onHide={() => this.handleAppModalClose()}
        backdrop={"static"}
        {...modalProps}
      >
        <React.Suspense fallback={<span />}>
          <ModalComponent onClose={() => this.handleAppModalClose()} />
        </React.Suspense>
      </Modal>
    );
  };

  render() {
    // return "404";
    if (!this.state.topBarContext.setRightButton) return null;

    const $app = (
      <TopBarContextProvider>
        <TopBarContext.Consumer>
          {({ isFullscreen }) => (
            <div
              ref={this._$app}
              className={classnames(
                styles.App,
                isFullscreen === true && styles.Fullscreen
              )}
            >
              <ThemeProvider theme={theme}>
                <SnackbarProvider>
                  <TopBar />
                  <React.Suspense fallback={<div>loading...</div>}>
                    {isMobile ? (
                      <Switch>{routes}</Switch>
                    ) : (
                        <Switch>{routes}</Switch>
                    )}
                  </React.Suspense>
                  <Zendesk defer zendeskKey={ZENDESK_KEY} {...setting} onLoaded={() => console.log('is loaded')} />
                  <Footer />
                  {this.renderAppModal()}
                  <LoadingAnimationPage loading={this.props.loading} />
                  <div non-scroll={"true"}>
                    <ToastNotifier />
                  </div>
                  <Notification />
                  {fragmentRoutes}
                  <MemoryRouter history={modalHistory}>
                    <ModalHandler
                      dialog={this.state.dialog}
                      navigateToModal={this.props.navigateToModal}
                      parentRouter={{
                        history: this.props.history,
                        location: this.props.location,
                        match: this.props.match
                      }}
                    />
                  </MemoryRouter>
                </SnackbarProvider>
              </ThemeProvider>
            </div>
          )}
        </TopBarContext.Consumer>
      </TopBarContextProvider>
    );
    if (isMobile)
      return (
        <MobileAppPortalContext.Provider value={this._$app.current}>
          {$app}
        </MobileAppPortalContext.Provider>
      );
    return $app;
  }
}

export default App;
// export default hot(App);
