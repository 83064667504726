import React from "react";
import { Redirect, withRouter } from "react-router";
import withSimpleForm from "../../utils/SimpleForm";
import { connect } from "react-redux";
import { createValidator } from "../../utils/form";
import { beforeLogin, forgot, login } from "../../store/user/actions";

const withLoginPage = Component => {
  @withRouter
  @withSimpleForm({
    entries: props => ({
      email:
        new URLSearchParams(props.location.search || "").get("email") || "",
      password: ""
    }),
    validation: {
      email: "required|email",
      password: "required"
    }
  })
  @connect(state => ({
    user: state.user
  }))
  class WithLoginPage extends React.Component {
    state = {
      forgotConsent: false,
      forgotPwdErrMessage: '',
      isLoggingIn: false
    };

    _validator;

    constructor(props) {
      super(props);
      this._validator = createValidator(this);
    }

    componentDidMount() {
      // this.props.topBarContext.setRightButton(this.renderRightButton);
      const { user } = this.props;
      if (user.status !== "login-success" || user.status !== "update-success" || user.obj.role !== "USER") {
        this.props.dispatch(beforeLogin());
      }
    }

    /**
     * @private
     * */
    get isForgotPassword() {
      return this.props.location.pathname === "/login/forgot";
    }

    /**
     * @private
     * */
    doLogin = e => {
      if (e) e.preventDefault();
      if (this.state.isLoggingIn) return;
      if (this.props.validate())
        this.setState({ isLoggingIn: true }, () => {
          this.props.dispatch(login(this.props.form)).catch(() => {
            this.setState({ isLoggingIn: false });
          });
        });
    };

    doLoginMobile = () => {
      if (this.state.isLoggingIn) return;
      if (!this._validator.allValid()) {
        this._validator.showMessages();
        return;
      }
      this.setState({ isLoggingIn: true }, () => {
        this.props.dispatch(login(this.props.form)).catch(() => {
          this.setState({ isLoggingIn: false });
        });
      });
    };

    /**
     * @private
     * */
    doForgot = () =>
      this.props
        .dispatch(forgot(this.props.form))
        .then(() => this.setState({ forgotConsent: true }))
        .catch((err) => {
          this.setState({ forgotPwdErrMessage: err.message })
        })

    doForgotMobile = () => {
      if (!this._validator.fieldValid("Email")) {
        this._validator.showMessages();
        //  this.setState({ forgotPwdErrMessage: '' })
        return;
      }
      return this.props
        .dispatch(forgot(this.props.form))
        .then(() => this.setState({ forgotConsent: true }))
        .catch((err) => {
          //console.error(err)
          this.setState({ forgotPwdErrMessage: err.message })
        });
    }

    render() {
      const self = this;
      if (self.props.user.status === "login-success") {
        return (
          <Redirect
            to={self.props.location.state?.from?.pathname || "/event"}
          />
        );
      }
      return (
        <Component
          {...self.props}
          pageActions={{
            form: self.props.form,
            state: self.state,
            setState: self.setState,
            validator: self._validator,
            doLogin: self.doLogin,
            doLoginMobile: self.doLoginMobile,
            doForgot: self.doForgot,
            doForgotMobile: self.doForgotMobile,
            isForgotPassword: self.isForgotPassword
          }}
        />
      );
    }
  }

  return WithLoginPage;
};

export default withLoginPage;
