import React from "react";
import { connect } from "react-redux";
import {
  ExhSelectorGetCurrent,
  ExhSelectorGetExhibitorOfUser,
  GetExhibitorOfUserStatus
} from "../../store/exhibitor/selectors";
import { ExhibitorActionSelect } from "../../store/exhibitor/actions";
import { compose } from "../../utils/common";
import withEnsureLogin from "../../HOCs/withEnsureLogin";

const withTopBarExhibitor = Component => {
  return compose(
    withEnsureLogin,
    connect(state => ({
      exhibitors: ExhSelectorGetExhibitorOfUser(state),
      status: GetExhibitorOfUserStatus(state),
      current: ExhSelectorGetCurrent(state)
    }))
  )(({ exhibitors, status, current, dispatch }) => {
    React.useEffect(() => {
      // if (!isEmpty(userObj)) {
      // if (exhibitors.length === 0) dispatch(ExhibitorActionFetchAll());
      if (exhibitors.length > 0 && current === null)
        dispatch(ExhibitorActionSelect(exhibitors[0]));
      // }
    }, [current, dispatch, exhibitors]);

    if (status !== "fetched") return null;
    if (typeof current === "undefined" || current === null)
      return <Component noExhibitor={true} />;
    return (
      <Component
        noExhibitor={false}
        exhibitors={exhibitors}
        current={current}
        onSelect={exhibitor =>
          exhibitor.id !== current.id &&
          dispatch(ExhibitorActionSelect(exhibitor))
          .catch(err => console.log(err.message))
          .then(res => {
            window.location.reload()
          })
        }
      />
    );
  });
};

export default withTopBarExhibitor;
