import React from 'react'

const BoothTypeBadge = ({ id, bgColor, name, isSmall = false, ...props }) => (
    <span
        className={`custom-badge`}
        style={{ 
            backgroundColor: bgColor,
            color: `inherit`,
            border: ["white", "#fff", "#FFF", "#ffffff", "#FFFFFF"].includes(bgColor) && `1px #414042AB solid`,
            ...isSmall ? {
                paddingTop: `0`,
                paddingBottom: `0`,
            } : {}
        }}
        {...props}
    >
        {name}
    </span>
)

export default BoothTypeBadge