import React from "react";
import { connect } from "react-redux";
import Responsive from "../UI/Responsive/Responsive";
import { InternalSideBarDesktop } from "./SideBarDesktop/SideBarDesktop";
// import { InternalSideBarMobile } from "./TopBarMobile/SideBarMobile";

function SideBar({ user, theme }) {
  if (user.obj?.role !== 'ADMIN') return (<></>)
  return (
    <Responsive
      // desktop={
      //   user.obj?.role === 'ADMIN'
      //     ? InternalSideBarDesktop
      //     : SideBarDesktop
      // }
      desktop={InternalSideBarDesktop}
      // mobile={
      //   user.obj?.role === 'ADMIN'
      //     ? InternalSideBarMobile
      //     : SideBarMobile
      // }
      user={user}
      theme={theme}
    />
  )
}

export default connect(state => ({ user: state.user }))(SideBar)