import { axiosApi } from "../../utils/api";
import UserActionTypes, {
  USER_ACTION_LOGIN,
  USER_ACTION_LOGIN_BEFORE,
  USER_ACTION_LOGIN_SUCCESS,
  USER_ACTION_REG_SUBMIT,
  USER_ACTION_REG_SUBMIT_SUCCESS,
  USER_ACTION_UPDATE_SUCCESS
} from "./const";
import aes from "crypto-js/aes";
import { WAIT_FOR_ACTION, ERROR_ACTION } from "redux-wait-for-action";

const _beforeLogin = () => ({ type: USER_ACTION_LOGIN_BEFORE }),
  _logout = () => ({ type: UserActionTypes.LOGOUT });

export function beforeLogin() {
  return dispatch => dispatch(_beforeLogin());
}

export function login({ email, password, isAdmin = false }) {
  return {
    type: USER_ACTION_LOGIN,
    email,
    password,
    isAdmin,
    [WAIT_FOR_ACTION]: USER_ACTION_LOGIN_SUCCESS,
    [ERROR_ACTION]: UserActionTypes.LOGIN_ERROR
  };
}

export function logout() {
  return dispatch => {
    dispatch(_logout());
    dispatch(_beforeLogin());
  };
}

export function forgot({ email }) {
  return async dispatch => {
    const ts = new Date().getTime().toString();
    const { status, data } = await axiosApi.post(
      "/v1/u/forgot-password?ts=" + ts,
      {
        tok_cus: aes.encrypt(email, ts).toString()
      }
    );
    if (data.err) throw new Error(data.err.message);
    console.log(status, data);
  };
}

export function UserActionRegister({ email }) {
  return async dispatch => {
    const ts = new Date().getTime().toString();
    const { data } = await axiosApi.post(
      "/v1/u/e/get-verification-email?ts=" + ts,
      {
        tok_cus: aes.encrypt(email, ts).toString()
      }
    );
    if (data.err) throw new Error(data.err.message);
  };
}

export function UserActionRegisterComplete(form) {
  return {
    type: USER_ACTION_REG_SUBMIT,
    form,
    [WAIT_FOR_ACTION]: USER_ACTION_REG_SUBMIT_SUCCESS
  };
}

export const fetchUserSuccess = user => ({
    type: USER_ACTION_UPDATE_SUCCESS,
    user
  });


export const getProfile = dispatch =>
  new Promise(resolve => {
    // dispatch(_fetchUser());
    axiosApi
      .auth()
      .get("/v1/u/me")
      .then(({ status, data }) => {
        if (status === 200) {
          dispatch(fetchUserSuccess(data));
        } else {
          // dispatch(_fetchIUsersError(data));
          console.log(data);
        }
        resolve();
      });
  });