import React, { useEffect } from "react";
import styles from "./UserTopBar.module.scss";
import ExhibitorBadge from "../UI/ExhibitorBadge/ExhibitorBadge";
import classnames from "classnames";
// import Dropdown from "../UI/Dropdown/Dropdown";
import withTopBarExhibitor from "../../controllers/withTopBarExhibitor/withTopBarExhibitor";
import withExhibitorStatusStyling from "../../HOCs/withExhibitorStatusStyling";
import { Badge } from "react-bootstrap";
import { compose } from "redux";
import withEnsureCurrentExhibitor from "HOCs/withEnsureCurrentExhibitor";
import { axiosApi } from "utils/api";
import { useSelector } from "react-redux";

const ExhibitorName = withExhibitorStatusStyling(
  ({ exhibitor, exhibitorStatusTextClassName }) => {
    return (
      <>
        <span
          className={classnames(
            styles.StatusIndicator,
            exhibitorStatusTextClassName
          )}
        >
          {exhibitor.newestStatus.id === 1 ? "Pending" : (exhibitor.newestStatus.id === 2 ? "Rejected" : "Activated")}
        </span>
        {exhibitor.brand_name}
      </>
    );
  }
);

const UserTopBarExhibitorList = compose(
  withTopBarExhibitor
)(({ exhibitors, noExhibitor, current, onSelect }) => {
    const user = useSelector(state => state.user.obj);
    // NOTE: cause reducer has been dispatched after logged in, we dont need fetch again
    const [credit, setCredit] = React.useState(0); 

    const getCreditAmount = async () => {
      try {
          const { data } = await axiosApi
              .auth(user.auth)
              .get(`/v1/u/e/user-credit/${current.id}`);
          // console.log(data)
          setCredit(data.credit)
      } catch (e){
        console.log(e)
        return null;
      }
  };

    useEffect(() => {
      if (current && user) {
        getCreditAmount()
      }
    }, [current, user])

    
    if (noExhibitor)
      return (
        <div className={"mr-3"}>
          <i>No exhibitors</i>
        </div>
      );
    return (
      <div className={styles.Toggle}>
        <span>{current.brand_name}</span>
        <Badge style={{ background: "#343a40", color: "#FFFFFF", fontSize: 13, marginLeft: 10, marginRight: 10 }}>Credit: ${credit.toFixed(2)}</Badge>
        <ExhibitorBadge role={current.user_role_on_exh} />
      </div>
    );
  }
);

export default UserTopBarExhibitorList;
