import axios from "axios";
import { IsUserTokenExpired } from "../store/user/selectors";
import { login } from "../store/user/actions";
import configureStore from "../store/store";
import {
  NotiActionsCloseToast,
  NotiActionsEnqueueToast
} from "../store/noti/actions";

const { store } = configureStore();

const createInstance = baseURL =>
    axios.create({
      baseURL,
      validateStatus: code => code >= 200
    }),
  axiosApi = createInstance(process.env.REACT_APP_API_HOST),
  axiosApiMockup = createInstance(process.env.REACT_APP_API_MOCKUP_HOST);

Object.defineProperty(axiosApi, "mockup", {
  get() {
    return axiosApiMockup;
  }
});

const axiosMethods = ["get", "post", "put", "delete"];

const promiseTimeout = function(ms, promise) {
  // Create a promise that rejects in <ms> milliseconds
  let timeout = new Promise((resolve, reject) => {
    let id = setTimeout(() => {
      clearTimeout(id);
      reject("promise timeout");
    }, ms);
  });
  return Promise.race([promise, timeout]);
};
const retryTimeout = (fn, context, args, timeout, count = 0) =>
  new Promise((resolve, reject) => {
    if (count === 1) {

    }
    if (count === 2) {

      reject("max retries");
    } else {
      const resolveFn = rs => {
        store.dispatch(NotiActionsCloseToast("network-retry"));
        resolve(rs);
      };
      promiseTimeout(timeout, fn.apply(context, args))
        .then(resolveFn)
        .catch(err => {
          if (err !== "promise timeout") reject(err);
          else
            retryTimeout(fn, context, args, timeout, count + 1)
              .then(resolveFn)
              .catch(reject);
        });
    }
  });

function enhancedWithRefreshToken(authApi) {
  const newFnCreator = oldFn => {
    return async function(...args) {
      const state = store.getState(),
        {
          user: { authentication }
        } = state;
      if(authentication === undefined) {
        // return false
        return retryTimeout(oldFn, this, args, 20000);
      }else {
        if (IsUserTokenExpired(state)) {
          await store.dispatch(login(authentication));
        }
        authApi.defaults.headers.common[
          "Authorization"
        ] = store.getState().user.obj.auth.jwt;
        // return oldFn.apply(this, args);
        return retryTimeout(oldFn, this, args, 20000);
      }
    };
  };
  for (let method of axiosMethods) {
    const oldFn = authApi[method],
      __enhanced = "__enhanced";
    if (!oldFn.hasOwnProperty(__enhanced)) {
      const newFn = newFnCreator(oldFn);
      Object.defineProperty(newFn, __enhanced, { value: true });
      authApi[method] = newFn.bind(authApi);
    }
  }
}

[
  [axiosApi, process.env.REACT_APP_API_HOST],
  [axiosApiMockup, process.env.REACT_APP_API_MOCKUP_HOST]
].forEach(([api, apiUrl]) => {
  Object.defineProperty(api, "auth", {
    get() {
      return () => {
        // const state = store.getState(),
          // { user } = state;
        // if (!(user.status === "login-success" && typeof user.obj === "object"))
        //   throw new Error("Must logged in before using authenticated-API");
        // if (IsUserTokenExpired(state)) throw new Error("Token expired!");
        const authApi = createInstance(apiUrl);
        enhancedWithRefreshToken(authApi);
        // console.log(authApi.post);
        return authApi;
      };
    }
  });
});

export { axiosApi, axiosApiMockup };

export const createTimestamp = () => new Date().getTime().toString();
