import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Nav = props => {

    const isActive = path => {
        return path.includes(window.location.pathname) ? 'ms-0 active' : 'text-dark'
    }
    
    return (
        <div style={{ maxWidth: "100vw", overflow: "scroll" }}>
            <nav className="nav nav-borders" style={{ width: 600 }}>
                <Link className={`nav-link ${isActive("/user/profile")}`} to="/user/profile">My Account</Link>
                <Link className={`nav-link ${isActive("/user/change-password")}`}  to="/user/change-password">Change password</Link>
                <Link className={`nav-link ${isActive("/exhibitor/detail/?tab=0")}`}  to="/exhibitor/detail/?tab=0">Exhibitor Details</Link>
                <Link className={`nav-link ${isActive("/payment/manage/booking")}`}  to="/payment/manage/booking">My Bookings</Link>
                {/* <Link disabled className="nav-link text-dark" to="/user/payment-method">Payment Method</Link>
                <Link disabled className="nav-link text-dark" to="/user/notifications">Notifications</Link> */}
            </nav>
            <hr className="mt-0 mb-4" />
        </div>
    );
};

export default Nav;
