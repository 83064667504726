import React from "react";
import styles from "./ExternalRegisterMobile.module.scss";
import { compose } from "../../utils/common";
import { withTopBarContext } from "../../utils/TopBarContext";
import LoginBackgroundMobile from "../../fragments/LoginBackgroundMobile/LoginBackgroundMobile";
import { Redirect, Route, Switch, withRouter } from "react-router";
import InputGroup from "react-bootstrap/InputGroup";
import commonStyles from "../../styles/common.module.scss";
import Form from "react-bootstrap/Form";
import withRegister from "../../controllers/withRegister/withRegister";
import MobileBottomNavigator from "../../components/UI/MobileBottomNavigator/MobileBottomNavigator";
import { Link } from "react-router-dom";
// eslint-disable-next-line import/no-webpack-loader-syntax
import tc from "!!raw-loader!./tc.txt";
import { appValidators, withToken } from "../../utils/form";
import Grid from "@material-ui/core/Grid";
import withSimpleForm from "../../utils/SimpleForm";
import TextField from "@material-ui/core/TextField";
import PhoneNumberInput from "../../components/UI/PhoneNumberInput/PhoneNumberInput";
import PasswordInput from "../../components/UI/PasswordInput/PasswordInput";
import TextInput from "../../components/UI/TextInput/TextInput";
import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";
import { UserActionRegisterComplete } from "../../store/user/actions";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import TermsContent from "pages/Terms/Terms-Content";
import { Alert } from "@material-ui/lab";
import { axiosApi } from "utils/api";

const bgRegisterTransparent = require("../../images/bg-register-no-fill-ext.png");
const bgActivateAccTransparent = require("../../images/bg-activate-acc-no-fill-ext.png");
// const bgRegisterFormTransparent = require("../../images/bg-register-form-no-fill-ext.png");
const bgTokenExpiredTransparent = require("../../images/bg-token-expired-no-fill-ext.png");

const StepOne = ({ pageActions, formBinding, form, history }) => {
  
  return (
    <LoginBackgroundMobile hasBgImg={bgRegisterTransparent}>
      <h2 className="text-primary">Create an account</h2>
      
      <Form.Group>
        <div style={{ width: "80%" }} className={"text-muted"}>
          Sign up to get started!
        </div>
      </Form.Group>
      <Form.Group>      
        <TextInput
          id={"email"}
          label="Email"
          placeholder={"Enter your email address"}
          type="email"
          size={"lg"}
          variant={"grey"}
          style={{ marginBottom: "0.7rem" }}
          {...formBinding("email")}
        >
        </TextInput>
        {pageActions.validator.message("Email", form.email, "required")}
      </Form.Group>

      <div>
      </div>
      <MobileBottomNavigator>
        <Link to={"/login"} className={styles.noUppercase}>
          <strong>Back</strong>
        </Link>
          <Link to={"/register/accept"} className={styles.noUppercase}>
            <span>next</span>
            {/* <FontAwesomeIcon
              icon={faChevronRight}
              className={"ml-2"}
            /> */}
          </Link>
      </MobileBottomNavigator>
    </LoginBackgroundMobile>
  );
};

const StepTwo = ({ history, pageActions }) => {
  const onAlert = (title, message, buttons) => {
    confirmAlert({
        title,
        message,
        buttons: buttons ? buttons :[
            {
                label: 'Close'
            }
        ]
    });
}
  return (
    <LoginBackgroundMobile hasLogo={false}>
      <h4 className="text-primary text-center" style={{ marginTop: "1.5rem" }}>Event's Terms Of Contract</h4>
      <div style={{ textAlign: "justify" }}>
        <TermsContent />
      </div>
      <MobileBottomNavigator>
        <Link to={"/register/email"} className={styles.noUppercase}><strong>back</strong></Link>
        <a
          href={`javascript:void(0)`}
          onClick={() =>
            pageActions.doRegister().then(() => history.push("/register/check")).catch(() => {
              onAlert("Sorry", "Email is already registered. Please use another email address.")
            })
          }
        >
          accept t&c
        </a>
      </MobileBottomNavigator>
    </LoginBackgroundMobile>
  );
};

const StepThree = ({ form }) => {
  return(
    <LoginBackgroundMobile hasBgImg={bgActivateAccTransparent}>
      {!form.email && (<Redirect to={"/login"} />)}
      <h2 className="text-primary">Activate account</h2>
      <p>
        Kindly check <strong>{form.email}</strong> for e-mail verification
      </p>
      <MobileBottomNavigator>
        <Link to={"/login"} className={""}>
          <span>Return to login</span>
        </Link>
      </MobileBottomNavigator>
    </LoginBackgroundMobile>
  )
}

const StepFour = compose(
  withRouter,
  withToken,
  withSimpleForm({
    entries: props => ({
      token: props.token.token,
      first_name: "",
      last_name: "",
      mobile: "",
      password: ""
    }),
    validation: {
      email: "required|email",
      first_name: "required",
      mobile: "required",
      password: "required|password"
    },
    customValidatorProps: {
      messages: {
        required: ":attribute must not be empty."
      },
      validators: {
        password: appValidators.password
      }
    }
  })
)(
  ({
    form,
    formBinding,
    token,
    validateToken,
    validate,
    validator,
    renderFieldValidator,
    history,
    pageActions
  }) => {
    const $firstName = React.useRef(null),
      [confirmPassword, setConfirmPassword] = React.useState(""),
      [mobileValue, setMobileValue] = React.useState(""),
      dispatch = useDispatch(),
      [error, setError] = React.useState("");

    const submit = () => {
      if (validate())
        validateToken().then(async () => {
          if (!token.error) {
            const urlParams = window.location.href.split("/continue?token=");
            const res = await axiosApi.post("/v1/u/account", {
              ...form,
              token: form.token !== "" ? form.token : (urlParams.length > 0 ? urlParams[1]: "")
            });
            if (res.status === 400) {
              setError(res.data?.err.message || "Invalid token / Token expired.")
            } else {
              history.replace("/login")
            }
          } else {
            setError("Invalid token / Token expired.")
          }
        })
    }

    React.useEffect(() => {
      if ($firstName.current) $firstName.current.focus()
    }, [token])

    if (token.validating) return null
    // if (token.error) {
    //   token.alertError("Return to login page");
    //   return <Redirect to={"/login"} />;
    // }
    return (
      <LoginBackgroundMobile hasBgImg={token.error && bgTokenExpiredTransparent}>
        <h2 className="text-primary">{token.error ? "Token error" : "Final step"}</h2>

        {(token.error || token.token === "" || !token.token) && (
          <Form>
            <Form.Group>
              <div className={"text-muted"}>
                Your activation link has expired!
              </div>
            </Form.Group>
            <Form.Group>
              <Grid container spacing={2} wrap="wrap-reverse">
                <Grid item xs={12} sm={6} fullWidth>
                  <Button
                    className={`w-100 ${styles.textEllipsis}`}
                    style={{}}
                    variant={"light"}
                    onClick={() => history.push("/login")}
                  >
                    Back to login
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} fullWidth>
                  <Button
                    className={`w-100 ${styles.Uppercase} ${styles.textEllipsis}`}
                    variant={"primary"}
                    onClick={() => history.push("/login")}
                  >
                    Resend
                  </Button>
                </Grid>
              </Grid>
            </Form.Group>
          </Form>
        )}
          
        {!token.error && token.token?.length > 0 && (
          <Form>
            <Form.Group>
              <Grid container spacing={2}>
                <Grid item sm={8} xs={8} md={8} style={{ paddingBottom: 0 }}>
                  <TextInput
                    ref={$firstName}
                    label="First Name"
                    name={"fname"}
                    type={"text"}
                    placeholder={"First Name"}
                    {...formBinding("first_name")}
                    variant="outlined"
                  />
                </Grid>
                <Grid item sm={4} xs={4} md={4} style={{ paddingBottom: 0 }}>
                  <TextInput
                    className={`${styles.LastName}`}
                    label="Last Name"
                    name={"lname"}
                    type={"text"}
                    placeholder={"Last Name"}
                    {...formBinding("last_name")}
                    variant="outlined"
                  />
                </Grid>
                <Grid item sm={8} xs={8} md={8} style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }}>
                  {renderFieldValidator("first_name", "First name")}
                </Grid>
              </Grid>
            </Form.Group>
            <Form.Group>
              <PhoneNumberInput
                name={"mobile"}
                type={"tel"}
                autocomplete={"tel"}
                placeholder={"Mobile number"}
                label={"Mobile Number"}
                defaultCountry={"65"}
                onInput={({ target }) => setMobileValue(target.value)}
                {...formBinding("mobile")}
              />
              {[
                validator.message(
                  "Mobile",
                  mobileValue,
                  "required"
                )
              ]}
            </Form.Group>
            <Form.Group>
              <PasswordInput
                name={"password"}
                placeholder={"Enter your password"}
                variant={"dark"}
                {...formBinding("password")}
              >
                {renderFieldValidator("password", "Password")}
              </PasswordInput>
            </Form.Group>
            <Form.Group>
              <PasswordInput
                name={"password-confirm"}
                label={"Confirm Password"}
                placeholder={"Confirm your password"}
                value={confirmPassword}
                onChange={({ target }) => setConfirmPassword(target.value)}
                variant={"dark"}
              >
                {[
                  validator.message(
                    "Confirm Password",
                    confirmPassword,
                    "required|password"
                  ),
                  validator.message(
                    "",
                    confirmPassword === form.password,
                    "accepted",
                    {
                      messages: {
                        default: "Confirm password doesn't match password."
                      }
                    }
                  )
                ]}
              </PasswordInput>
            </Form.Group>
            <Form.Group>
              {error.length > 0 && <Alert style={{ marginBottom: 20 }} color="error" >{error}</Alert>}
              <Button
                variant={"primary"}
                onClick={submit}
                className={`w-100 ${styles.Uppercase}`}
                style={{ paddingLeft: "3rem", paddingRight: "3rem", marginBottom: "3rem"}}
                type={"button"}
              >
                Complete
              </Button>
            </Form.Group>
            
            <div className={styles.Register}>
              <span className={"text-muted"}>No thanks. </span>
              <Link to={"/login"} className={"no-link font-weight-bold"}>
                Return to login
              </Link>
            </div>
          </Form>
        )}

      </LoginBackgroundMobile>
    )
  }
)

const ExternalRegisterMobile = compose(
  withTopBarContext({ isFullscreen: true }),
  withRegister
)(({ pageActions, ...props }) => {
  return (
    <Switch location={props.location}>
      <Route path={"/register/email"}>
        <StepOne pageActions={pageActions} {...props} />
      </Route>
      <Route path={"/register/accept"}>
        <StepTwo pageActions={pageActions} {...props} />
      </Route>
      <Route path={"/register/check"}>
        <StepThree pageActions={pageActions} {...props} />
      </Route>
      <Route path={"/register/continue"}>
        <StepFour pageActions={pageActions} />
      </Route>
      <Route path={"*"}>
        <Redirect to={"/register/email"} />
      </Route>
    </Switch>
  );
});

export default ExternalRegisterMobile;
