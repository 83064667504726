import {
  takeLeading,
  takeLatest,
  take,
  put,
  select,
  call,
  fork,
  getContext,
  setContext
} from "@redux-saga/core/effects";
import {
  EXHIBITOR_ACTION_FETCH,
  EXHIBITOR_ACTION_FETCHED,
  ExhibitorActionType
} from "./const";
import { axiosApi } from "../../utils/api";
import { APIExternal, APIInternal } from "../../utils/constant";
import { takeLoginAction } from "../sagas";
import { REHYDRATE } from "redux-persist/lib/constants";
import isEmpty from "lodash/isEmpty";

function* exhibitorFetchAll() {
  const user = yield select(state => state.user);
  const { data: list } = yield call(
    axiosApi.auth(user).get,
    user.obj.role === "USER"
      ? APIExternal.GET_ALL_EXHIBITOR
      : APIInternal.GET_ALL_EXHIBITOR
  );
  yield put({ type: EXHIBITOR_ACTION_FETCHED, user, list });
}

function* hookFetched({ user, list }) {
  const state = yield select(state => state.exhibitor),
    existingSelectId = state.select[user.obj.id];
  let updateSelect = false,
    selected = -1;
  if (!existingSelectId) updateSelect = true;
  const index = list.findIndex(i => i.id * 1 === existingSelectId * 1);
  if (index < 0) updateSelect = true;
  else
    yield put({
      type: ExhibitorActionType.SELECT,
      user,
      item: list[index].id * 1
    });
  if (updateSelect && list.length > 0) selected = list[0].id * 1;
  if (selected > 0)
    yield put({ type: ExhibitorActionType.SELECT, user, item: selected });
}

export default function* exhibitorSagas() {
  yield takeLoginAction(null, function*() {
    yield put({ type: EXHIBITOR_ACTION_FETCH });
  });

  //fetch all
  yield takeLeading(EXHIBITOR_ACTION_FETCH, exhibitorFetchAll);
  yield takeLatest(EXHIBITOR_ACTION_FETCHED, hookFetched);
}

export const takeExhibitorSelectAction = (saga = function*() {}, ...args) =>
  fork(function*() {
    while (true) {
      const userId = yield select(state => state.user.obj.id);
      const action = yield take([
        ExhibitorActionType.SELECT,
        action =>
          action.type === REHYDRATE &&
          action.key === "exhibitorCurrent" &&
          !isEmpty(action.payload?.select[userId])
      ]);
      const isPersisted = yield getContext("exhibitorCurrent");
      if (!(action.type === REHYDRATE && isPersisted)) {
        yield setContext({ exhibitorCurrent: true });
        yield fork(saga, ...args.concat(action));
      }
    }
  });
