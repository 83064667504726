import React from "react";
import * as PropTypes from "prop-types";
import styles from "./ResetPasswordMobile.module.scss";
import { compose } from "../../utils/common";
import { withRouter } from "react-router";
import { withTopBarContext } from "../../utils/TopBarContext";
import { appValidators, withToken } from "../../utils/form";
import { axiosApi } from "../../utils/api";
import { Link } from "react-router-dom";
import PageTitle from "../../components/UI/PageTitle/PageTitle";
import PasswordInput from "../../components/UI/PasswordInput/PasswordInput";
import withSimpleForm from "../../utils/SimpleForm";
import LoginBackgroundMobile from "../../fragments/LoginBackgroundMobile/LoginBackgroundMobile";
import Grid from "@material-ui/core/Grid";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import AlertDismissable from "../../components/AlertDismissable/AlertDismissable";

const bgTokenExpiredTransparent = require("../../images/bg-token-expired-no-fill-ext.png");
const bgRegisterFormExtTransparent = require("../../images/bg-register-form-no-fill-ext.png");

const ResetPasswordMobile = compose(
  withTopBarContext({ isFullscreen: true }),
  withRouter,
  withToken,
  withSimpleForm({
    entries: props => ({
      token: props.token.token,
      password: "",
      confirmPassword: ""
    }),
    validation: {
      password: "required|password"
    },
    customValidatorProps: {
      messages: {
        required: ":attribute must not be empty."
      },
      validators: {
        password: appValidators.password
      }
    }
  })
)(
  ({
    form,
    formBinding,
    token,
    isAdmin,
    validateToken,
    validate,
    validator,
    renderFieldValidator,
    history,
    location,
    ...props
  }) => {
    const [confirmPassword, setConfirmPassword] = React.useState(``),
          [hasErrorMessage, setErrorMessage] = React.useState(``)

    const submit = () => {
      if (validate()) {
        verifyToken().then(() => {
          doResetPassword().then(res => {
            if (res) {
              history.push(`${isAdmin ? `/internal` : ``}/login?email=${res.email}`)
            }
          }).catch(err => {
            //  setErrorMessage(err.message)
            setErrorMessage(`Something went wrong, please try again!`)
            validateToken()
          })
        }).catch(err => {
          //  console.log(err.message)
          validateToken()
          setErrorMessage(``)
        })
      }
    }

    const verifyToken = async () => {
      const { data } = await axiosApi.post(`/v1/u/verify-token`, { token: token.token })
      if (data.err) {
        throw new Error(data.err.message)
        //return false
      }
      return true
    }

    const doResetPassword = async () => {

      const search = new URLSearchParams(location.search);
      let tokenFromURL = search.get("token");
      tokenFromURL = tokenFromURL.replace(/\s/g, "+");

      const { data } = await axiosApi.put(`/v1/u/reset-password`, 
      {
        ...form,
        token: tokenFromURL
      })
      if (data.err) {
        throw new Error(data.err.message)
      }
      if (data.success) {
        return data.user
      }
    }
    
    React.useEffect(() => {
    }, [])

    return(
      <LoginBackgroundMobile hasBgImg={token.error ? bgTokenExpiredTransparent : bgRegisterFormExtTransparent}>
        <h2 className="text-primary">{token.error ? `Token error` : `Reset password`}</h2>

        {token.error && (
          <Form>
            <Form.Group>
              <div className={"text-muted"}>
                Your password recovery link has expired!
              </div>
            </Form.Group>
            <Form.Group>
              <Grid container spacing={2} wrap="wrap-reverse">
                <Grid item xs={12} sm={6} fullWidth>
                  <Button
                    className={`w-100 ${styles.textEllipsis}`}
                    style={{}}
                    variant={"light"}
                    onClick={() => history.push("/login")}
                  >
                    Back to login
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} fullWidth>
                  <Button
                    className={`w-100 ${styles.Uppercase} ${styles.textEllipsis}`}
                    variant={"primary"}
                    onClick={() => history.push("/login/forgot")}
                  >
                    Resend
                  </Button>
                </Grid>
              </Grid>
            </Form.Group>
          </Form>
        )}

        {!token.error && (
          <Form>
            <Form.Group>
              <div style={{ width: "80%" }} className={"text-muted"}>
                Enter your new password below
              </div>
            </Form.Group>
            {hasErrorMessage && (
              <Form.Group>
                <AlertDismissable variant={"danger"}>
                  {hasErrorMessage}
                </AlertDismissable>
              </Form.Group>
            )}
            <Form.Group>
              <PasswordInput
                name={"password"}
                placeholder={"Enter your password"}
                variant={"dark"}
                {...formBinding("password")}
              >
                {renderFieldValidator("password", "Password")}
              </PasswordInput>
            </Form.Group>
            <Form.Group>
              <PasswordInput
                name={"confirmPassword"}
                label={"Confirm Password"}
                placeholder={"Confirm your password"}
                value={confirmPassword}
                onChange={({ target }) => setConfirmPassword(target.value)}
                variant={"dark"}
              >
                {[
                  validator.message(
                    "",
                    confirmPassword === form.password,
                    "accepted",
                    {
                      messages: {
                        default: "Confirm password doesn't match password."
                      }
                    }
                  )
                ]}
              </PasswordInput>
            </Form.Group>
            <Form.Group>
              <Button
                variant={"primary"}
                onClick={submit}
                className={`w-100 ${styles.Uppercase}`}
                style={{ paddingLeft: "3rem", paddingRight: "3rem", marginBottom: "3rem"}}
                type={"button"}
              >
                Reset my password
              </Button>
            </Form.Group>
            
            <div className={styles.BottomNaviagation}>
              <span className={"text-muted"}>No thanks. </span>
              <Link to={"/login"} className={"no-link font-weight-bold"}>
                Return to login
              </Link>
            </div>
          </Form>
        )}

      </LoginBackgroundMobile>
    )
  }
)

ResetPasswordMobile.propTypes = {
  isAdmin: PropTypes.bool
}
ResetPasswordMobile.defaultProps = {
  isAdmin: false
}

export default ResetPasswordMobile