import React from "react";
import styles from "./withExhibitorStatusStyling.module.scss";
import classnames from "classnames";

const exhibitorStatusClass = [
  null,
  styles.Draft,
  styles.Inactivated,
  styles.Standard,
  styles.FinalizedA,
  styles.FinalizedB,
  styles.FinalizedC,
  styles.FinalizedD
];

const withExhibitorStatusStyling = Component => {
  return function WithExhibitorStatusStyling(props) {
    const id =
      props.currentExhibitor?.newestStatus?.id ||
      props.exhibitor?.newestStatus?.id ||
      props.statusId;
    if (!id) return null;
    return (
      <Component
        exhibitorStatusClassName={exhibitorStatusClass[id]}
        exhibitorStatusTextClassName={classnames(
          exhibitorStatusClass[id],
          styles.Text
        )}
        {...props}
      />
    );
  };
};

export default withExhibitorStatusStyling;
