import React, { useEffect, useState } from "react";
import styles from "./TopBarMobile.module.scss";
import menuStyles from "../Menu.module.scss";
import { ReactComponent as HamburgerIcon } from "../../../images/HamburgerIconTransparent.svg";
import Drawer from "@material-ui/core/Drawer";
import withTopBar from "../withTopBar";
import classnames from "classnames";
import AuthLink from "../../AuthLink/AuthLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowDropDownRounded from '@material-ui/icons/ArrowDropDownRounded';
import TopBarMobileExhibitorList from "./TopBarMobileExhibitorList";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { connect, useDispatch, useSelector } from "react-redux";
import { logout } from "../../../store/user/actions";
import { Link, withRouter } from "react-router-dom";
import classNames from "classnames";
import CloseIcon from '@material-ui/icons/Close';
import { axiosApi } from "utils/api";
import { compose } from "redux";
import Badge from "react-bootstrap/Badge";
import BoothTypeBadge from "../../UI/BoothTypeBadge/BoothTypeBadge";
import {
  ExhSelectorGetCurrent,
} from "../../../store/exhibitor/selectors";
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import { confirmAlert } from "react-confirm-alert";
import { Button, Divider } from "@material-ui/core";
import { Button as ButtonBS } from "react-bootstrap";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { useHistory, useLocation } from "react-router";
import LoadingAnimationPage from "components/UI/LoadingAnimationPage/LoadingAnimationPage";
import IconButton from "../../../components/UI/IconButton/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import ReactMomentCountDown from "react-moment-countdown";
import Dropdown from 'react-bootstrap/Dropdown';
import { default as Dropdown2 } from 'react-bootstrap/Dropdown';
import { formatter } from "utils/datetime";
import {
  BookingActionClearCart,
  BookingActionRemoveFromCart,
  BookingActionFetchCart
} from "../../../store/booking/actions";
import withToast from "HOCs/withToast";
import { default as MuiBtn } from '@material-ui/core/Button';
import { Alert } from "@material-ui/lab";
import NotificationMenu from "components/UserTopBar/NotificationMenu";

const useAvatarStyle = makeStyles(theme => ({
  root: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    backgroundColor: "#e9e9e9"
  }
}));

const Hamburger = ({ onClick }) => (
  <span className={styles.Hamburger}>
    <HamburgerIcon onClick={onClick} />
  </span>
);

const NavBar = compose(
  withToast
)(({ open, setOpen, isFullscreen, toastSuccess }) => {
  const hamburger = <Hamburger onClick={() => setOpen(!open)} />;
  const location = useLocation(),
    dispatch = useDispatch(),
    history = useHistory();
  const carts = useSelector(state => { return state.booking ? state.booking.cart : false }),
    events = useSelector(state => state.event.list),
    currentExhibitor = useSelector(state => ExhSelectorGetCurrent(state)),
    boothType = useSelector(state => state.boothType);

  const eventIdFromUrl = location.pathname.replace("/event/", "") * 1;
  const cart = Object.values(carts)?.find(c => c.event_id === eventIdFromUrl);
  const event = events[eventIdFromUrl] || {};

  const [station, setStation] = useState(false);
  const [exhibitorStatus, defineExhibitorStatus] = React.useState(currentExhibitor?.newestStatus?.value);

  const DeleteFromCartIcon = ({ id }) => (
    <IconButton
      style={{ margin: "-.5rem -.5rem -.3rem .5rem", transform: 'scale(.75)' }}
      size={"small"}
      onClick={() => {
            setStation(true)
            const booth_no = cart?.booths?.find(
              item => item.id === id
            ).booth_no
            dispatch(BookingActionRemoveFromCart(event.id, id))
              .then(() => {
                toastSuccess(<>Removed <strong>{booth_no}</strong> from cart!</>)
                setStation(false)
              }
              )
      }}
      tooltip={{ title: "Delete item from cart", placement: "bottom" }}
    >
      <ClearIcon />
    </IconButton>
  )

  const RemoveAllFromCart = () => {
    setStation(true)
    const cartItemIds = cart?.booths?.map(booth => booth.id)
    dispatch(BookingActionClearCart(event.id, cartItemIds))
    .then(() => {
      toastSuccess("Successfully emptied cart!")
      setStation(false)
    })
    .catch((err) => console.log(err))
  }


  const CheckoutButton = () => {
    defineExhibitorStatus(currentExhibitor.newestStatus?.value)
    let isDisabled = false
    if (typeof exhibitorStatus === `undefined`
        || exhibitorStatus * 1 < 3
        || typeof cart?.id === `undefined`
        || cart?.booths?.length < 1) {
      isDisabled = true
    }
    return (
      <MuiBtn
        variant={"primary"}
        className={"w-100 text-uppercase"}
        disabled={isDisabled}
        onClick={() => _bookNow()}
      >
        Checkout
      </MuiBtn>
    )
  }

  const _bookNow = () => {
    const cart_id = cart.id
    if (typeof cart_id === `undefined`)
      if (process.env.NODE_ENV === `development`)
        throw new Error(`Something went wrong when do booking`)
      else console.error(`Something went wrong when do booking`)
    // props.navigateToModal(`/payment/detail/cart/${cart_id}`)
    history.push(`/checkout/cart/${cart_id}`)
  }

  const reloadCart = (t) => {
    if (t < 1000) {
      setTimeout(() => {
        confirmAlert({
          closeOnClickOutside: false,
          title: "",
          message: "You have exceeded the time limit and your reservation has been released. Please refresh your screen.",
          buttons:[
              {
                  label: 'Refresh',
                  onClick: () => window.location.reload()
              }
          ]
      });
        // dispatch(BookingActionFetchCart());
      }, 1000)
    }
  }

  if (isFullscreen === true)
    return <div className={styles.NavBarBase}>{hamburger}</div>;
  
    return (
    <div className={styles.NavBar} id="navbar-mobile">
      {hamburger}
      {/* <QooeeLogo className={styles.Logo} /> */}
      <div className={styles.ExhCtn}>
        {currentExhibitor?.id && <div className={styles.ExhOuter}>
          <TopBarMobileExhibitorList />
          <ArrowDropDownRounded className={styles.iconArrowDown} />
        </div>}
        {(location.pathname.match(`^/event/\\d+$`)) && (
            <Dropdown className="ml-4 align-self-center">
              <Dropdown.Toggle as={CartButton} />
              <Dropdown.Menu align={'left'} className={styles.DropdownCart}>
                <div>
                  <Dropdown2.Header className={`${styles.DropdownCartEventName} d-flex justify-content-between`}>
                    <div>
                      <strong>{event?.name}</strong>
                    </div>
                    {(cart?.booths?.length > 0) && (
                      <div>
                        <ins
                          onClick={() => RemoveAllFromCart()}
                          className={styles.DropdownClearCart}
                        >Clear</ins>
                      </div>
                    )}
                  </Dropdown2.Header>
                  <Dropdown2.Divider className={styles.DropdownCartDivider} />
                  <div className={styles.boxContent}>
                    {(!cart || cart?.booths?.length === 0) && <Alert className="m-4" severity="info">No items in cart.</Alert>}
                    {(cart?.booths?.length > 0) && cart?.booths?.map((item, index) => (
                        <div className={`d-flex justify-content-between ${styles.DropdownCartItem}`}>
                          <div>
                            {/* {console.log("cart",item)} */}
                            <span className={styles.DropdownCartItemName}>{item.booth_name} - {item.booth_type_name || boothType.booth.map[item.booth_type_id]?.name} {item.booth_name}</span>
                            <br />
                            <BoothTypeBadge
                              id={item.booth_type_id}
                              bgColor={boothType.booth.map[item.booth_type_id]?.color
                                || item.booth_type_color}
                              name={item.booth_type_name
                                || boothType.booth.map[item.booth_type_id]?.name}
                              isSmall={true}
                            />
                          </div>
                          <div className={`d-flex align-items-center ${styles.DropdownCartItemPricing}`}>
                            <div>{formatter.format(item.snapshot_price)}</div>
                            <DeleteFromCartIcon id={item.id} />
                          </div>
                        </div>
                    ))}
                  </div>
                  <div className={`mb-4`}></div>
                  <div className={styles.DropdownCartButton}>
                    <CheckoutButton />
                  </div>
                  {cart?.booths?.length > 0 && (
                    <div className={styles.DropdownCartTimeExpiried}>
                      <div className={styles.DropdownCartText}>
                        <ReactMomentCountDown
                            toDate={new Date(cart.expiry_time)}
                            // toDate={new Date("2022-12-18 02:56:00")}
                            // onCountDownEnd={() => console.log("ends up. Do something???")}
                            targetFormatMask={"m"}
                          />{' '}mins{' '} 
                          <ReactMomentCountDown
                            toDate={new Date(cart.expiry_time)}
                            onTick={(t) => reloadCart(t)}
                            // toDate={new Date("2022-12-18 02:56:00")}
                            // onCountDownEnd={() => reloadCart()}
                            targetFormatMask={"s"}
                          /> seconds left before cart is automatically emptied. 
                      </div>
                    </div>
                  )}
                  <LoadingAnimationPage loading={station} />
                </div>
              </Dropdown.Menu>
            </Dropdown>
          )}
          <NotificationMenu className="mr-3 align-self-center" mobile={true} />
      </div>
    </div>
  );
})

const CartButton = React.forwardRef(({ onClick }, ref) => {
  const location = useLocation();
  const carts = useSelector(state => {
    return state.booking ? state.booking.cart : false
  });
  const eventIdFromUrl = location.pathname.replace("/event/", "") * 1;
  const cart = Object.values(carts)?.find(c => c.event_id === eventIdFromUrl);
  const length = cart?.booths?.length ? cart?.booths?.length : 0;
  return (
    <div
      style={{ margin: `0 0`, cursor: "pointer" }}
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}>
      <ShoppingCartIcon style={{ color: "#333" }} />
      {length > 0 && <span style={{
        backgroundColor: "#e1297a",
        color: "white",
        width: 16,
        height: 16,
        position: "absolute",
        fontSize: 11,
        borderRadius: 10,
        textAlign: "center",
        right: -11,
        top: -6,
      }}>{length}</span>}
    </div>
  )
})


const TopBarMobile = compose(
  withTopBar,
  withRouter,
  connect(state => ({
    current: ExhSelectorGetCurrent(state)
  }))
)(
  ({ location, history, isFullscreen, isLoginSuccess, user, topBarMenu, current: currentExhibitor }) => {
    const [credit, setCredit] = React.useState(0); 
    const [open, setOpen] = React.useState(false),
      dispatch = useDispatch();
    
    const getCreditAmount = async () => {
        try {
            const { data } = await axiosApi
                .auth(user.auth)
                .get(`/v1/u/e/user-credit/${currentExhibitor.id}`);
            // console.log(data)
            setCredit(data.credit)
        } catch (e){
          console.log(e)
          return null;
        }
    };

    useEffect(() => {
      if (currentExhibitor && currentExhibitor?.categories?.length < 1 && !location.pathname?.includes("exhibitor/")) {
        confirmAlert({
          closeOnClickOutside: false,
          title: "Action required!",
          message: "Please select your exhibitor's category and declare what you will be selling.",
          buttons:[
              {
                  label: 'Go to Exhibitor Details',
                  onClick: () => history.push("/exhibitor/detail/?tab=0")
              }
          ]
        });
      }
    })

    useEffect(() => {
      if (currentExhibitor && user) {
        getCreditAmount()
      }
    }, [currentExhibitor, user])

    useEffect(() => {
      dispatch(BookingActionFetchCart());
    }, [currentExhibitor])
  

    return (
      <>
        <NavBar isFullscreen={isFullscreen} open={open} setOpen={setOpen} />
        <Drawer anchor={"left"} open={open} onClose={() => setOpen(false)}>

          <div className={styles.ListOuter}>
            <CloseIcon style={{ position: "absolute", right: 20, top: 20 }} onClick={() => setOpen(false)} />
            <div className={styles.List}>
              {isLoginSuccess && (
                <>
                  <div className={styles.User}>
                    <img height={50} width={50} style={{ borderRadius: 25 }} src={user?.profile_photo || '/user.jpeg'} alt="" />
                    <div className={"ml-3"}>
                      <h4 className={"d-inline-block mb-1 text-bold mt-1"}>
                        {user.first_name} {user.last_name}
                      </h4>
                      <h6>{user.email}</h6>
                      <Badge style={{ background: "#e10f76", color: "#FFFFFF", fontSize: 13 }}>Credit: ${credit.toFixed(2)}</Badge>
                    </div>
                    <div className={styles.LogoutIcon}    
                      onClick={() => {
                        dispatch(logout());
                        setOpen(false);
                      }}>
                      <span style={{ width: "1.5rem" }}>
                        <ExitToAppIcon />
                      </span>
                    </div>
                  </div>
                </>
              )}
              <ul
                className={classNames(menuStyles.MenuItems, menuStyles.MenuItemsMobile)}
                style={{ marginTop: 20 }}
                onClick={() => setOpen(false)}
              >
                {topBarMenu.map(([link, activeFn, title, icon, opts = {}]) => (
                  <li
                    key={link}
                    className={classnames(
                      menuStyles.MenuItem,
                      opts.child && [
                        menuStyles.Child,
                        [null, menuStyles.Child1][opts.child]
                      ]
                    )}
                  >
                    <AuthLink
                      className={"no-link"}
                      activeClassName={menuStyles.Active}
                      to={link}
                      isActive={activeFn}
                    >
                      {title}
                    </AuthLink>
                  </li>
                ))}

                {!isLoginSuccess && <li
                  className={classnames(
                    menuStyles.MenuItem
                  )}
                >
                  <AuthLink
                    className={"no-link"}
                    activeClassName={menuStyles.Active}
                    to={"/login"}
                  >
                    Sign In / Register
                  </AuthLink>
                </li>}
              </ul>
              <Divider className="ml-3" />
              <div className="mt-4 d-flex">
                <ButtonBS 
                  onClick={() => {
                    history.push("/event")
                    setOpen(false)}
                  }
                  variant="primary"
                  className={classNames(styles.btnBookABooth, "mr-2")} >Book a booth</ButtonBS>
                <ButtonBS 
                  onClick={() => {
                    // history.push("/event")

                    setOpen(false)
                    // document.querySelector(`launcher`).click();
                    const zendeskIframe = document.querySelector(`#launcher`);
                    zendeskIframe.contentWindow.document.getElementsByTagName("button")[0].click();
                  }}
                  variant="secondary"
                  className={styles.btnBookABooth} >
                    <HeadsetMicIcon className="mr-2" />
                    Support
                  </ButtonBS>
              </div>
            </div>
            {/* {!isLoginSuccess && (
              <div
                className={styles.Logout}
              >
                <div onClick={() => setOpen(false)}><Link to={"/login"}>Login</Link></div>
                <div onClick={() => setOpen(false)}><Link to={"/register"}>Register</Link></div>
              </div>
            )} */}
            {/* {isLoginSuccess && (
              <div
                className={styles.Logout}
                onClick={() => {
                  dispatch(logout());
                  setOpen(false);
                }}
              >
                <span className="d-inline-block" style={{ width: "2rem" }}>
                  <FontAwesomeIcon icon={faSignOutAlt} />
                </span>
                Log out
              </div>
            )} */}
          </div>
        </Drawer>
      </>
    );
  }
);

export default TopBarMobile;
// export default hot(TopBarMobile);

export const InternalTopBarMobile = withTopBar(
  ({ isFullscreen, isLoginSuccess, user, topBarMenu }) => {
    const [open, setOpen] = React.useState(false),
      avatarStyle = useAvatarStyle(),
      dispatch = useDispatch();
    return (
      <>
        <NavBar isFullscreen={isFullscreen} open={open} setOpen={setOpen} />
        <Drawer anchor={"left"} open={open} onClose={() => setOpen(false)}>
          <div className={styles.ListOuter}>
            <div className={styles.List}>
              {isLoginSuccess && (
                <div className={styles.User}>
                  <img height={50} width={50} style={{ borderRadius: 25 }} src={user?.profile_photo || '/user.jpeg'} alt="" />
                  <div className={"ml-3"}>
                    <h5 className={"text-uppercase d-inline-block mb-1"}>
                      {user.first_name} {user.last_name}
                    </h5>
                    <span>{user.email}</span>
                  </div>
                </div>
              )}
              <ul
                className={menuStyles.MenuItems}
                onClick={() => setOpen(false)}
              >
                {topBarMenu.map(([link, activeFn, title, icon, opts = {}]) => (
                  <li
                    key={link}
                    className={classnames(
                      menuStyles.MenuItem,
                      opts.child && [
                        menuStyles.Child,
                        [null, menuStyles.Child1][opts.child]
                      ]
                    )}
                  >
                    <AuthLink
                      className={"no-link"}
                      activeClassName={menuStyles.Active}
                      to={link}
                      isActive={activeFn}
                    >
                      {icon && (
                        <span
                          className={"d-inline-block"}
                          style={{ width: "2rem" }}
                        >
                          <FontAwesomeIcon icon={icon} />
                        </span>
                      )}
                      {title}
                    </AuthLink>
                  </li>
                ))}
              </ul>
              {/* <Button>Book a booth</Button> */}
            </div>
          </div>
        </Drawer>
      </>
    );
  }
);
