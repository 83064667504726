import { onMessageListener, requestForToken } from "firebase";
import React, { useEffect, useState } from "react";
import { APIExternal, APIReplParams } from "utils/constant";
import { axiosApi } from "utils/api";
import { useSelector } from "react-redux";


const withNotification = Component => {
  
  function WithAppModal(props) {
    const [notifications, setNotifications] = useState([])
    const [loading, setLoading] = useState(false)
    const [notiAllowed, setNotiAllowed] = useState(false)
    const [options, setOptions] = useState({
      page: 1, pageSize: 6
    })
    const user = useSelector(state => state.user.obj);

    const fetchData = async () => {
        setLoading(true);
        
        const { data } = await axiosApi
        .auth()
        .get(`${APIExternal.GET_ALL_NOTIFICATION}?page=${options.page}&pageSize=${options.pageSize}`);

        setNotifications(data)
        setLoading(false);
    }

    const readNotification = async (id) => {
      await axiosApi
      .auth()
      .put(APIReplParams(APIExternal.READ_NOTI, { id }));

      fetchData();
    }

    const readAllNotification = async () => {
      await axiosApi
      .auth()
      .put(APIExternal.GET_ALL_NOTIFICATION);

      fetchData();
    }

    const viewMore = () => {
      setOptions({
        ...options,
        pageSize: options.pageSize + 6
      })
    }

    useEffect(() => {
      if (user && user.id){
          requestForToken()
          .then((token) => {
              // console.log(token)
              setNotiAllowed(token !== "err") 
          })
          .catch((err) => {
            console.log('failed: ', err)
            setNotiAllowed(false);
          });
      }
    }, [user])

    useEffect(() => {
        fetchData();
    }, [options])

    onMessageListener()
    .then((payload) => {
      fetchData();
    })
    .catch((err) => console.log('failed: ', err));


    return <Component {...props} notiEnabled={notiAllowed} loadingNotification={loading} notifications={notifications} viewMoreNotification={viewMore} fetchNotifications={fetchData} readNotification={readNotification} readAllNotification={readAllNotification} />;
  }
  return WithAppModal;
};

export default withNotification;
