import React from "react";
import styles from "./ExhibitorAddConsent.module.scss";
// import * as PropTypes from "prop-types";

const ExhibitorAddConsent = ({ children }) => (
  <div className="mt-5 d-flex flex-column align-items-center">
    <div className={styles.NoExhibitor}>
      <h5>Welcome onboard!</h5>
      <p>
      Before you can book a booth at our events, you will need to be a Vendor (we call it Exhibitor).
To create your first Exhibitor account, hit the button below!
      </p>
    </div>
    {children}
  </div>
);

export default ExhibitorAddConsent;
