import React from "react";
import PasswordValidator from "password-validator";
import styles from "./form.module.scss";
import { axiosApi } from "./api";
import AlertDismissable from "../components/AlertDismissable/AlertDismissable";
import deepAssign from "deep-assign";
import { stringToSlug } from "./common";
import SimpleReactValidator from "./simple-react-validator";

const pwdValidator = new PasswordValidator();
pwdValidator.is().min(8);

export const appValidators = {
  password: {
    message: "Password must have 8 characters length at least",
    rule: val => pwdValidator.validate(val),
    required: true
  }
};

/**
 *
 * @param context
 * @param props
 * @returns {SimpleReactValidator}
 */
export const createValidator = (context, props) =>
  new SimpleReactValidator(
    deepAssign(
      {},
      {
        autoForceUpdate: context,
        messages: {
          required: ":attribute must not be empty."
        },
        // className: styles.ValidationMessage,
        validators: {
          atMax: {
            message: ":attribute must be :value characters at max",
            rule: (val, [length], { helpers }) =>
              helpers.testRegex(val, new RegExp(`^.{1,${length}}$`)),
            messageReplace: (message, [length]) =>
              message.replace(":value", length),
            required: true
          },
          nric: {
            message: "Must be a valid NRIC",
            rule: val => {
              if (val.length !== 9) return false;

              val = val.toUpperCase();

              let i,
                icArray = [];
              for (i = 0; i < 9; i++) {
                icArray[i] = val.charAt(i);
              }

              icArray[1] = parseInt(icArray[1], 10) * 2;
              icArray[2] = parseInt(icArray[2], 10) * 7;
              icArray[3] = parseInt(icArray[3], 10) * 6;
              icArray[4] = parseInt(icArray[4], 10) * 5;
              icArray[5] = parseInt(icArray[5], 10) * 4;
              icArray[6] = parseInt(icArray[6], 10) * 3;
              icArray[7] = parseInt(icArray[7], 10) * 2;

              let weight = 0;
              for (i = 1; i < 8; i++) {
                weight += icArray[i];
              }

              let offset = icArray[0] === "T" || icArray[0] === "G" ? 4 : 0;
              let temp = (offset + weight) % 11;

              let st = ["J", "Z", "I", "H", "G", "F", "E", "D", "C", "B", "A"];
              let fg = ["X", "W", "U", "T", "R", "Q", "P", "N", "M", "L", "K"];

              let theAlpha;
              if (icArray[0] === "S" || icArray[0] === "T") {
                theAlpha = st[temp];
              } else if (icArray[0] === "F" || icArray[0] === "G") {
                theAlpha = fg[temp];
              }

              return icArray[8] === theAlpha;
            },
            required: true
          }
        },
        element: (message, className) =>
          <div className={`${styles.ValidationMessage} ${className}`}>{message}</div>
      },
      props
    )
  );

export const withToken = Component => {
  return class extends React.Component {
    state = {
      validating: true,
      validated: false,
      error: null,
      token: ""
    };
    constructor(props) {
      if (!props.location)
        throw new Error("Must place withRouter HOC before withToken");
      super(props);
    }
    async componentDidMount() {
      await this._validateToken();
    }

    /**
     *
     * @returns {Promise<void>}
     * @private
     */
    _validateToken = async () => {
      const search = new URLSearchParams(this.props.location.search);
      let token = search.get("token");

      //Check URL must have token parameter
      if (typeof token === "undefined" || token === null || token === "") {
        this.setState({
          validating: false,
          error: { message: "Token invalid" }
        });
        return;
      }

      //continue to validate token
      token = token.replace(/\s/g, "+");
      const {
        data: { err }
      } = await axiosApi.post("/v1/u/verify-token", { token });
      if (err) this.setState({ validating: false, error: err });
      else {
        this.setState({
          error: null,
          validating: false,
          validated: true,
          token
        });
      }
    };

    /**
     *
     * @param additional
     * @returns {JSX.Element}
     * @private
     */
    _renderError = (additional = null) => (
      <AlertDismissable
        letiant={"danger"}
        data-error={stringToSlug(this.state.error.message)}
      >
        <h4>Token error</h4>
        <p>
          {this.state.error.message}. {additional}
        </p>
      </AlertDismissable>
    );

    /**
     *
     * @param additional
     * @private
     */
    _alertError = (additional = null) => {
      window.alert(
        `Token error: ${this.state.error.message}` +
          (additional ? `. ${additional}` : "")
      );
    };

    render() {
      const token = {
        ...this.state,
        renderError: this._renderError,
        alertError: this._alertError
      };
      return (
        <Component
          {...this.props}
          token={token}
          validateToken={this._validateToken}
        />
      );
    }
  };
};

export const Keyboard = {
  isTab: e => (e.keyCode || e.charCode) === 9,
  isEnter: e => (e.keyCode || e.charCode) === 13,
  isTabOrEnter: e => Keyboard.isTab(e) || Keyboard.isEnter(e)
};
