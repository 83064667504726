import React from "react";
import {
  EXHIBITOR_ACTION_FETCH,
  EXHIBITOR_ACTION_FETCHED,
  ExhibitorActionType
} from "./const";
import { axiosApi } from "../../utils/api";
import { APIReplParams } from "../../utils/constant";
import { APIInternal, APIExternal } from "../../utils/constant";
import { NotiActionsEnqueueToast } from "../noti/actions";
import { WAIT_FOR_ACTION } from "redux-wait-for-action";

const _fetchOne = (user, item) => ({
  type: ExhibitorActionType.FETCHED_ONE,
  user,
  item
});

export const ExhibitorActionFetchAll = () => ({
  type: EXHIBITOR_ACTION_FETCH,
  [WAIT_FOR_ACTION]: EXHIBITOR_ACTION_FETCHED
});

export const exhibitorFetchOne = ({ id = null } = {}) => async (
  dispatch,
  getState
) => {
  if (!id) throw new Error("TODO: implement GET /api/exhibitor/:item");
  const { user } = getState(),
    { data: item } = await axiosApi
      .auth(user)
      .get(
        user.obj.role === "USER"
          ? APIReplParams(APIExternal.GET_ONE_EXHIBITOR, { id })
          : APIReplParams(APIInternal.GET_ONE_EXHIBITOR, { id })
      );
  dispatch(_fetchOne(user, item));
  return item;
};

export const exhibitorUpdateStatus = (item, status) => async (
  dispatch,
  getState
) => {
  const { user } = getState(),
    {
      data: { success }
    } = await axiosApi.auth().put(
      user.obj.role === "USER"
        ? ""
        : APIReplParams(APIInternal.UPDATE_EXHIBITOR_STATUS, {
            id: item.id
          }),
      { status }
    );
  if (success) {
    const updated = await dispatch(exhibitorFetchOne(item));
    return updated;
  }
};

export const ExhibitorActionSelect = item => async (dispatch, getState) => {
  const { user } = getState();
  dispatch({ type: ExhibitorActionType.SELECT, item, user })
  //   .catch(err => {
  //     throw new Error(err.message)
  //   })
  // return true
}

export const exhibitorAdd = form => async (dispatch, getState) => {
  const { user } = getState();
  if (user.obj.role !== "USER")
    throw new Error("Permission denied. Only user can create exhibitor");
  const {
    data: { success, err, id }
  } = await axiosApi.auth().post(APIExternal.CREATE_EXHIBITOR, form);
  if (success) {
    await dispatch(exhibitorFetchOne({ id }));
    await dispatch(ExhibitorActionSelect({ id }));
  } else {
    // console.log(JSON.stringify(err))
    throw new Error(err?.message);
  }
};

export const exhibitorUpdate = (id, form) => async (dispatch, getState) => {
  const { user } = getState();

  const {
    data: { success, err }
  } = await axiosApi
    .auth(user)
    .put(APIReplParams(APIExternal.UPDATE_EXHIBITOR, { id }), form);
  if (success) {
    await dispatch(exhibitorFetchOne({ id }));
    await dispatch(ExhibitorActionSelect({ id }));
  } else console.error(err);
};

export const ExhibitorActionUpdateCredit = ({ id }, form) => async (
  dispatch,
  getState
) => {
  const { user } = getState();

  const {
    data: { success, exhibitor_id, ...err }
  } = await axiosApi
    .auth(user)
    .put(APIReplParams(APIInternal.UPDATE_EXHIBITOR_CREDIT, { id }), form);
  if (JSON.stringify(err) !== "{}") throw new Error(JSON.stringify(err));
  else {
    const { bn_or_nric_name } = dispatch(
      exhibitorFetchOne({ id: exhibitor_id })
    );
    dispatch(
      NotiActionsEnqueueToast({
        message: (
          <>
            Update credit rating for&nbsp;<strong>{bn_or_nric_name}</strong>
            &nbnsp;succeeded
          </>
        )
      })
    );
  }
};
