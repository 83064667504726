import { onMessageListener, requestForToken } from 'firebase';
import React, {useState, useEffect, useRef} from 'react'
import toast, { Toaster } from 'react-hot-toast';
import NotificationSound from "./notification-sound.mp3";
import { useSelector } from 'react-redux';
import { axiosApi } from 'utils/api';
import { APIExternal } from 'utils/constant';

const notify = ({ body, title, image, url }) => toast.custom((t) => (
    <div
      className={`${
        t.visible ? 'animate-enter' : 'animate-leave'
      } max-w-md w-full bg-white shadow rounded-lg pointer-events-auto d-flex ring-1 ring-black ring-opacity-5`}
    >
      <div className="flex-1 w-0 p-3">
        <div className="d-flex items-start">
          {image && <div className="flex-shrink-0 pt-0.5">
            <img
              className="rounded" style={{ width: "3rem", height: "3rem" }}
              src={image}
              alt={title}
            />
          </div>}
          <div className="ml-3 flex-1">
            <p className="text-sm font-medium text-gray-900 mb-0">
              <strong>{title}</strong>
            </p>
            <p className="mt-1 text-sm text-gray mb-0 mt-1">
              {body}
            </p>
          </div>
        </div>
      </div>
      <div className="flex border-l border-gray">
        <button
          onClick={() => toast.dismiss(t.id)}
          style={{ borderLeft: "1px solid #c1bfbfaa", borderRight: "none", borderTop: "none", borderBottom: "none"}}
          className="w-full h-100 bg-white rounded rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          Close
        </button>
      </div>
    </div>
  ))

const Notification = () => {
  const [notification, setNotification] = useState({title: '', body: ''});
  const audioPlayer = useRef(null);
  const user = useSelector(state => state.user.obj);

  const playAudio = () => {
    if (audioPlayer.current) audioPlayer.current.play();
  }

  useEffect(() => {
    if (notification?.title ){
        playAudio();
        notify(notification)
    }
  }, [notification])

  useEffect(() => {
    if (user && user.id){
        requestForToken()
        .then((token) => {
            if (token != "err") updateToken(token)
        })
        .catch((err) => console.log('failed: ', err));
    }
  }, [user])

  const updateToken = async (token) => {
    axiosApi
    .auth()
    .post(
        APIExternal.UPDATE_FCM_TOKEN,
        { token }
    )
  }

  requestForToken()

  onMessageListener()
    .then((payload) => {
      console.log(payload)
      setNotification(payload.notification); 
    })
    .catch((err) => console.log('failed: ', err));

  return (
    <>
        <Toaster position="top-right" />
        <audio ref={audioPlayer} src={NotificationSound} />
    </>
  )
}

export default Notification