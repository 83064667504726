
export const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "qooee-987c2.firebaseapp.com",
    projectId: "qooee-987c2",
    storageBucket: "qooee-987c2.appspot.com",
    messagingSenderId: "994831750735",
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: "G-V42XEYS2QN"
  };
  