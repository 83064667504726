import React from "react";
// import styles from "./FileUpload.module.scss";
import * as PropTypes from "prop-types";
import withFileUpload from "../../../HOCs/withFileUpload";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons/faCloudUploadAlt";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";
import classnames from "classnames";

@withFileUpload(props => props)
class FileUpload extends React.PureComponent {
  static propTypes = {
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    variant: PropTypes.string,
    faCheck: PropTypes.object
  };

  /**
   *
   * @returns {*}
   * @private
   */
  get _isAnyFileUploading() {
    return this.props.value.reduce(
      (acc, val) => acc || val.state === "uploading",
      false
    );
  }
  render() {
    const self = this,
      Handler = self.props.fileUpload.FileUploadHandler;
    return (
      <TooltipWrapper
        title={self.props.tooltip}
        placement={self.props.tooltipPlacement}
      >
        <div>
          <Handler onUploadCompleted={self.props.onUploadCompleted || (v => v)}>
            <Button
              variant={self.props.variant}
              className={classnames("px-3", self.props.className)}
              onClick={self.props.fileUpload.openFileDialog}
              disabled={self._isAnyFileUploading}
            >
              {self._isAnyFileUploading ? (
                <FontAwesomeIcon icon={faSpinner} pulse className={"mr-2"} />
              ) : (
                <FontAwesomeIcon
                  icon={self.props.faCheck || faCloudUploadAlt}
                  className={"mr-2"}
                />
              )}
              {self.props.children || "Upload file"}
            </Button>
          </Handler>
        </div>
      </TooltipWrapper>
    );
  }
}

FileUpload.defaultProps = {
  variant: "primary",
  faCheck: null
};

export default FileUpload;
