export const USER_STATUS = {
  DELETED: -1,
  INACTIVE: 0,
  ACTIVE: 1
};

// TODO: get all event API
export function APIReplParams(path, params = {}) {
  const url = Object.entries(params).reduce((p, [key, value]) => {
    return p.replace(`:${key}`, value);
  }, path);
  if (/:[a-zA-Z]+/.test(url))
    throw new Error(`Lack parameter(s) inside API URL: ${path}`);
  return url;
}
export function APIAuthAPI(user, ApiName, params = undefined) {
  let apiStr = (user === "undefined" ? APIExternal : APIExternal)[ApiName]; //user.obj.role === "USER" 
  // if (typeof apiStr === "undefined") {
  //   throw new Error(`API ${ApiName} doesn't exist!`);
  // }
  if (typeof params === "object") apiStr = APIReplParams(apiStr, params);
  return apiStr;
}
export const APIInternal = {
  GET_ALL_EXHIBITOR: "v1/e/a/get-all",
  GET_ONE_EXHIBITOR: "v1/e/a/get-exh-by-id/:id",
  UPDATE_EXHIBITOR_STATUS: "v1/e/a/status/:id",
  UPDATE_EXHIBITOR_CREDIT: "v1/e/a/credit-rating/:id",
  MODIFY_USER_PROFILE: "v1/a/u/:id/info",
  GET_ALL_EVENT: "v1/evt/a/get-all",
  GET_ONE_EVENT: "v1/evt/a/info/:id",
  UPDATE_EVENT: "v1/evt/a/update-event/:id",
  ADD_EVENT: "v1/evt/a/add-event",
  BOOTH_LAYOUT: "v1/evt/a/booth-layouts/:eventId",
  BOOTH_TYPES_ALL: "v1/b/a/all-booth-types",
  GET_BOOTH_TYPE_BY_SLUG: "v1/b/a/booth-type-by-slug/:slug",
  BOOTH_ADD_TYPE: "v1/b/a/event/:eventId/booth-type",
  BOOTH_EDIT_TYPE: "v1/b/a/event/:eventId/booth-type/:id",
  POST_UPLOAD_BOOTH_SPEC: "/v1/b/a/event/:eventId/booths",
  POST_UPLOAD_BOOTH_PRICING: "/v1/b/a/event/:eventId/pricing",
  GET_ALL_PRICING: "/v1/b/a/event/:eventId/pricing",
  GET_ALL_BOOTHS: "/v1/b/a/event/:eventId/booths",
  DEL_ALL_BOOTHS: "/v1/b/a/event/:eventId/booths",
  DEL_ALL_PRICINGS: "/v1/b/a/event/:eventId/pricing",
  DEL_BOOTH_TYPE: "v1/b/a/booth-type/:id",
  GET_ALL_ORDER: "v1/booking/a/orders"
};
export const APIExternal = {
  GET_ALL_EXHIBITOR: "v1/e/get-all-exhibitors",
  GET_ONE_EXHIBITOR: "v1/e/get-exhibitor-by-id/:id",
  CREATE_EXHIBITOR: "v1/e/create-exhibitor",
  QUERY_USER: "v1/u/info/:email",
  UPDATE_EXHIBITOR: "v1/e/update-exhibitor/:id",
  GET_EXHIBITOR_MEMBERS: "v1/e/exhibitor-member/:id",
  POST_TAG_MEMBER_TO_EXHIBITOR: "v1/e/add-member-to-exhibitor/:id",
  DEL_REMOVE_MEMBER_FROM_EXHIBITOR: "/v1/e/remove-member/:exhibitorId/:userId",
  GET_ALL_EVENT: "v1/evt/get-all/:exhId",
  GET_ONE_EVENT: "/v1/evt/info/:id/:exhId",
  GET_ALL_BOOTHS: "/v1/b/event/:eventId/exhibitor/:exhId/booths-available",
  GET_ALL_BOOTHS_NO_PRICING: "/v1/b/event/:eventId/booths-available",
  BOOTH_TYPES_ALL: "v1/b/event/:eventId/all-booth-types",
  GET_ALL_CART: "v1/booking/exhibitor/:exhibitorId/basket",
  ADD_TO_CART: "v1/booking/event/:eventId/exhibitor/:exhibitorId/basket",
  ORDER_PLACE_PUT:
    "v1/booking/event/:eventId/exhibitor/:exhibitorId/place-order",
ORDER_CONFIRM_CART:
    "v1/booking/confirm-cart",
ORDER_VALIDATE_CART:
    "v1/booking/validate-cart",
  GET_ALL_ORDER: "v1/booking/exhibitor/:exhibitorId/orders",
  USER_PROFILE: "v1/u/me",
  CHANGE_PASS: "v1/u/change-password",
  UPDATE_FCM_TOKEN: "v1/u/e/fcm-token",
  GET_ALL_NOTIFICATION: "v1/u/e/notifications",
  READ_NOTI: "v1/u/e/notifications/:id",
};
export const APIMisc = {
  POST_UPLOAD: "v1/r/upload",
  POST_UPLOAD_PUBLIC: "v1/r/public-upload",
  GET_ALL_EXHIBITOR_STATUS: "v1/e/all-exhibitor-status"
};


export const optionsExhibitorType = [
  { value: 'retail', label: 'Retail' },
  { value: 'fnb', label: 'F&B' },
  { value: 'wedding', label: 'Wedding' },
]

export const optionsCategories = [
  {
    type: "Food",
    filter: "fnb",
    options: [
      {
          "label": "Takoyaki",
          "value": "Takoyaki"
      },
      {
          "label": "Sushi",
          "value": "Sushi"
      },
      {
          "label": "Burgers",
          "value": "Burgers"
      },
      {
          "label": "Meat",
          "value": "Meat"
      },
      {
          "label": "Satay",
          "value": "Satay"
      },
      {
          "label": "Noodles",
          "value": "Noodles"
      },
      {
          "label": "Pasta",
          "value": "Pasta"
      },
      {
          "label": "Rice",
          "value": "Rice"
      },
      {
          "label": "Banana Fritters",
          "value": "Banana Fritters"
      },
      {
          "label": "Seafood",
          "value": "Seafood"
      },
      {
          "label": "Ice-cream & Yogurt",
          "value": "Ice-cream & Yogurt"
      },
      {
          "label": "Mac & Cheese",
          "value": "Mac & Cheese"
      },
      {
          "label": "Hotdogs, Sausages & Sandwiches",
          "value": "Hotdogs, Sausages & Sandwiches"
      },
      {
          "label": "Burritos",
          "value": "Burritos"
      },
      {
          "label": "Pretzels",
          "value": "Pretzels"
      },
      {
          "label": "Pizza",
          "value": "Pizza"
      },
      {
          "label": "Traditional Kueh",
          "value": "Traditional Kueh"
      },
      {
          "label": "Western Food",
          "value": "Western Food"
      },
      {
          "label": "Malay Food",
          "value": "Malay Food"
      },
      {
          "label": "Japanese Food",
          "value": "Japanese Food"
      },
      {
          "label": "Thai Food",
          "value": "Thai Food"
      },
      {
          "label": "Spanish Food",
          "value": "Spanish Food"
      },
      {
          "label": "French Food",
          "value": "French Food"
      },
      {
          "label": "Korean Food",
          "value": "Korean Food"
      },
      {
          "label": "Chinese Food",
          "value": "Chinese Food"
      },
      {
          "label": "Others (Please specify)",
          "value": "food-others"
      }
  ]
  },
  {
    type: "Beverages",
    filter: "fnb",
    options: [
      {
          "label": "Air Balang",
          "value": "Air Balang"
      },
      {
          "label": "Fruit Juice",
          "value": "Fruit Juice"
      },
      {
          "label": "Smoothies",
          "value": "Smoothies"
      },
      {
          "label": "Hot",
          "value": "Hot"
      },
      {
          "label": "Cold",
          "value": "Cold"
      },
      {
          "label": "Others (Please specify)",
          "value": "drink-others"
      }
  ]
  },
  {
    type: "Retail",
    filter: "retail",
    options: [
      {
          "label": "Kids",
          "value": "Kids"
      },
      {
          "label": "Toys",
          "value": "Toys"
      },
      {
          "label": "Books",
          "value": "Books"
      },
      {
          "label": "Home Decor",
          "value": "Home Decor"
      },
      {
          "label": "Gadgets",
          "value": "Gadgets"
      },
      {
          "label": "Stickers",
          "value": "Stickers"
      },
      {
          "label": "Stationeries",
          "value": "Stationeries"
      },
      {
          "label": "Health & Wellness",
          "value": "Health & Wellness"
      },
      {
          "label": "Fragrance & Perfume",
          "value": "Fragrance & Perfume"
      },
      {
          "label": "Beauty",
          "value": "Beauty"
      },
      {
          "label": "Skin Care",
          "value": "Skin Care"
      },
      {
          "label": "Hair Care",
          "value": "Hair Care"
      },
      {
          "label": "Women's Accessories",
          "value": "Women's Accessories"
      },
      {
          "label": "Women's Apparels",
          "value": "Women's Apparels"
      },
      {
          "label": "Women's Bags & Shoes",
          "value": "Women's Bags & Shoes"
      },
      {
          "label": "Women's Jewellery",
          "value": "Women's Jewellery"
      },
      {
          "label": "Men's Apparel",
          "value": "Men's Apparel"
      },
      {
          "label": "Men's Accessories",
          "value": "Men's Accessories"
      },
      {
          "label": "Men's Bags & Shoes",
          "value": "Men's Bags & Shoes"
      },
      {
          "label": "Pre-packed Food",
          "value": "Pre-packed Food"
      },
      {
          "label": "Bottled Drinks",
          "value": "Bottled Drinks"
      },
      {
          "label": "Others (Please specify)",
          "value": "retail-others"
      }
  ]
  },
  {
    type: "Wedding",
    filter: "wedding",
    options: [
        {
            "label": "Bridal Services",
            "value": "Bridal Services"
        },
        { "label": "Make-Up Services", "value": "Make-Up Services" },
        { "label": "Hairdressing", "value": "Hairdressing" },
        { "label": "Wedding Decorations", "value": "Wedding Decorations" },
        { "label": "Wedding Photography", "value": "Wedding Photography" },
        { "label": "Wedding Videography", "value": "Wedding Videography" },
        { "label": "Henna Services", "value": "Henna Services" },
        { "label": "Wedding Planner", "value": "Wedding Planner" },
        { "label": "Wedding Catering", "value": "Wedding Catering" },
        { "label": "Wedding Cake Design", "value": "Wedding Cake Design" },
        { "label": "Florist", "value": "Florist" },
        { "label": "Wedding DJ/Band", "value": "Wedding DJ/Band" },
        { "label": "Kompang and Other Percussion Groups", "value": "Kompang and Other Percussion Groups" },
        { "label": "Wedding Dress and Suit Hire", "value": "Wedding Dress and Suit Hire" },
        { "label": "Wedding Stationery", "value": "Wedding Stationery" },
        { "label": "Wedding Favours", "value": "Wedding Favours" },
        { "label": "Wedding Car or Limousine Hire", "value": "Wedding Car or Limousine Hire" },
        { "label": "Accommodation Services", "value": "Accommodation Services" },
        { "label": "Event Lighting", "value": "Event Lighting" },
        { "label": "Photo Booth Hire", "value": "Photo Booth Hire" },
        { "label": "Wedding Dance Lessons", "value": "Wedding Dance Lessons" },
        { "label": "Destination Wedding Planning", "value": "Destination Wedding Planning" },
        { "label": "Gift Services", "value": "Gift Services" },
        { "label": "Honeymoon Planning", "value": "Honeymoon Planning" },
        {
            "label": "Others (Please specify)",
            "value": "wedding-others"
        }
  ]
  }
]