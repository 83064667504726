import { axiosApi } from "../../utils/api";
import EventActionType, {
  EVENT_ACTION_ADD,
  EVENT_ACTION_ADDED,
  EVENT_ACTION_ERROR
} from "./const";
import EventSelectors from "./selectors";
import { APIAuthAPI, APIInternal } from "../../utils/constant";
import {
  WAIT_FOR_ACTION,
  ERROR_ACTION,
  CALLBACK_ARGUMENT
} from "redux-wait-for-action";
// import ActionHooks, { conditionLoginSuccess } from "../middlewares/actionHooks";

const _fetching = () => ({ type: EventActionType.FETCHING }),
  _fetched = list => ({ type: EventActionType.FETCHED, list }),
  _updating = item => ({ type: EventActionType.UPDATING, item }),
  _fetchingOne = item => ({ type: EventActionType.ONE_FETCHING, item }),
  _fetchedOne = item => ({ type: EventActionType.ONE_FETCHED, item }),
  // wrap action inside this function to prevent re-fetch object (list or event item) again
  bewareFetching = (obj, fn) => (dispatch, getState) => {
    if (typeof fn !== "function") throw new Error("fn must be a function");
    const state = getState();
    if (
      EventSelectors.isObjectFetching("list")(state) ||
      EventSelectors.isObjectFetching(obj)(state)
    )
      return Promise.resolve();
    return fn.call(null, dispatch, getState);
  };

const fetchAll = ({ exhId }) =>
  bewareFetching("list", async (dispatch, getState) => {
    const state = getState();
    dispatch(_fetching());
    const { data: list } = await axiosApi
      .auth()
      .get(APIAuthAPI(state.user, "GET_ALL_EVENT", { exhId }));
    dispatch(_fetched(list));
  });

const fetchOne = ({ id, exhId }) =>
  bewareFetching(id, async (dispatch, getState) => {
    if (typeof id === "undefined") throw new Error("Event ID is empty");
    const state = getState();
    dispatch(_fetchingOne({ id }));
    const { data: item } = await axiosApi
      .auth()
      .get(APIAuthAPI(state.user, "GET_ONE_EVENT", { id, exhId }));
    dispatch(_fetchedOne(item));
    return item;
  });

const addNew = form => ({
  type: EVENT_ACTION_ADD,
  form,
  [WAIT_FOR_ACTION]: EVENT_ACTION_ADDED,
  [ERROR_ACTION]: EVENT_ACTION_ERROR,
  [CALLBACK_ARGUMENT]: action => action.item
});

const update = (item, { id, ...form } = item) =>
  bewareFetching(item.id, async (dispatch, getState) => {
    const state = getState();
    dispatch(_updating(item));
    const {
      data: { success }
    } = await axiosApi
      .auth()
      .put(APIAuthAPI(state.user, "UPDATE_EVENT", { id: item.id }), form);
    if (success) return dispatch(fetchOne(item));
  });

const EventActions = {
  fetchAll,
  fetchOne,
  update,
  addNew
};
export default EventActions;

// ActionHooks.register(...conditionLoginSuccess, fetchAll);
