import React from "react";
import { combineRootSagas, putWait, takeLeading } from "../../utils/store";
import _ from "lodash";
import moment from 'moment';
import {
  FAPP_ACTION_FETCH,
  FAPP_ACTION_FETCH_SUCCESS,
  FAPP_ACTION_REMARK,
  FAPP_ACTION_REMARK_SUCCESS,
  FAPP_ACTION_SAVE,
  FAPP_ACTION_SAVE_SUCCESS,
  FAPP_ACTION_SUBMIT,
  FAPP_ACTION_SUBMIT_SUCCESS,
  FHANDLER_ACTION_FETCH,
  FHANDLER_ACTION_FETCH_SUCCESS,
  FHANDLER_ACTION_SAVE,
  FHANDLER_ACTION_SAVE_SUCCESS,
  FHANDLER_ACTION_UPDATE,
  FHANDLER_ACTION_UPDATE_SUCCESS,
  FSTALL_ACTION_APPROVE,
  FSTALL_ACTION_APPROVE_SUCCESS,
  FSTALL_ACTION_ERROR,
  FSTALL_ACTION_FETCH,
  FSTALL_ACTION_FETCH_ADMIN,
  FSTALL_ACTION_FETCH_ADMIN_SUCCESS,
  FSTALL_ACTION_FETCH_SUCCESS,
  FSTALL_ACTION_REJECT,
  FSTALL_ACTION_REJECT_SUCCESS,
  FSTALL_ACTION_SAVE,
  FSTALL_ACTION_SAVE_SUCCESS,
  FSTALL_ACTION_SUBMIT,
  FSTALL_ACTION_SUBMIT_SUCCESS
} from "./const";
import { put, call, takeEvery } from "@redux-saga/core/effects";
import { axiosApi } from "../../utils/api";
import { APIReplParams } from "../../utils/constant";
import { takeLoginAction } from "../sagas";
import { NotiActionsEnqueueToast } from "../noti/actions";

function refineForm(form) {
  form.stall_number *= 1;
}
function* _spawnErr(error) {
  console.error(JSON.stringify(error));
  // yield put(
  //   NotiActionsEnqueueToast({
  //     message: (
  //       <div>
  //         {/* <h5>F&B Licences Error</h5> */}
  //         <span>{_.get(error, "message", "An entry is required or has an invalid value. Please correct and try again.")}</span>
  //       </div>
  //     ),
  //     options: { variant: "error", autoHideDuration: 2000 }
  //   })
  // )
  yield put({ type: FSTALL_ACTION_ERROR, error });
}
function* _fetch(exhibitorId) {
  yield put({ type: FSTALL_ACTION_FETCH, exhibitorId });
}

function* licencesFetch() {
  yield takeLeading(FSTALL_ACTION_FETCH, function*({ exhibitorId }) {
    try {
      const { data: licences } = yield call(
        axiosApi.auth().get,
        APIReplParams("/v1/evt/exhibitor/:exhibitorId/foodstall-licence", {
          exhibitorId
        })
      );
      yield put({
        type: FSTALL_ACTION_FETCH_SUCCESS,
        exhibitorId,
        licences
      });
    } catch (e) {
      throw _spawnErr(e);
    }
  });
  yield takeLeading(FSTALL_ACTION_FETCH_ADMIN, function*() {
    try {
      const { data: licences } = yield call(
        axiosApi.auth().get,
        "/v1/evt/a/foodstall-licence"
      );
      yield put({ type: FSTALL_ACTION_FETCH_ADMIN_SUCCESS, licences });
    } catch (e) {
      yield _spawnErr(e);
    }
  });
  yield takeLoginAction(true, function*() {
    yield put({ type: FSTALL_ACTION_FETCH_ADMIN });
  });
}

function* licencesSave() {
  yield takeLeading(FSTALL_ACTION_SAVE, function*({
    eventId,
    exhibitorId,
    form
  }) {
    try {
      refineForm(form);
      const { id = null, dob, date_of_issue, date_of_expiry, items, ...rest } = form,
        isAdd = id === null;
      const payload = {
        ...(dob ? { dob: moment(dob).format("YYYY-MM-DD") } : {}),
        ...(date_of_issue ? { date_of_issue: moment(date_of_issue).format("DD-MM-YYYY") } : {}),
        ...(date_of_expiry ? { date_of_expiry: moment(date_of_expiry).format("DD-MM-YYYY") } : {}),
        ...(items ? { items: items.map(i => _.pickBy(i, _.identity))} : {}),
        ...rest
      };
      const { data } = yield call(
          axiosApi.auth()[isAdd ? "post" : "put"],
          APIReplParams(
            isAdd
              ? "/v1/evt/event/:eventId/exhibitor/:exhibitorId/foodstall-licence"
              : "/v1/evt/event/:eventId/exhibitor/:exhibitorId/foodstall-licence/:id",
            {
              eventId,
              exhibitorId,
              id
            }
          ),
          payload
        ),
        { success, licence, error } = data;
      if (success === true && !error) {
        yield put({
          type: FSTALL_ACTION_SAVE_SUCCESS,
          eventId,
          exhibitorId,
          licence
        });
        yield _fetch(exhibitorId);
      } else yield _spawnErr(error);
    } catch (e) {
      yield _spawnErr(e);
    }
  });
}

function* licencesSubmit() {
  yield takeLeading(FSTALL_ACTION_SUBMIT, function*({
    eventId,
    exhibitorId,
    id
  }) {
    try {
      const { data } = yield call(
          axiosApi.auth().put,
          APIReplParams(
            "/v1/evt/event/:eventId/exhibitor/:exhibitorId/submit-foodstall-licence/:id",
            {
              eventId,
              exhibitorId,
              id
            }
          )
        ),
        { success, err } = data;
      if (success && !err) {
        yield put({ type: FSTALL_ACTION_SUBMIT_SUCCESS });
        yield _fetch(exhibitorId);
      } else yield _spawnErr(err);
    } catch (e) {
      yield _spawnErr(e);
    }
  });
}

function* licenceAdmin() {
  yield takeLeading([FSTALL_ACTION_APPROVE, FSTALL_ACTION_REJECT], function*({
    type,
    id
  }) {
    const isApprove = type === FSTALL_ACTION_APPROVE;
    try {
      const { data } = yield call(
          axiosApi.auth().put,
          APIReplParams(`/v1/evt/a/:type-foodstall-licence/:id`, {
            id,
            type: isApprove ? "approve" : "reject"
          })
        ),
        { success, err } = data;
      if (success) {
        yield put({
          type: isApprove
            ? FSTALL_ACTION_APPROVE_SUCCESS
            : FSTALL_ACTION_REJECT_SUCCESS
        });
        yield put({ type: FSTALL_ACTION_FETCH_ADMIN });
      } else yield _spawnErr(err);
    } catch (e) {
      yield _spawnErr(e);
    }
  });
}

function* fhandlerFetch() {
  yield takeLeading(FHANDLER_ACTION_FETCH, function*({ exhibitorId, role }) {
    try {
      const { data } = yield call(
        axiosApi.auth().get,
        `/v1/e/${
          role?.isNotClient ? "a/" : ""
        }exhibitor/${exhibitorId}/foodstall-handler`
      );
      // yield put({
      //   type: FHANDLER_ACTION_FETCH_SUCCESS,
      //   exhibitorId,
      //   licences: mockFhandler
      // });
      yield put({
        type: FHANDLER_ACTION_FETCH_SUCCESS,
        exhibitorId,
        licences: data || []
      });
    } catch (e) {
      yield _spawnErr(e);
    }
  });
}

function* fhandlerPost() {
  yield takeLeading(FHANDLER_ACTION_SAVE, function*({ exhibitorId, form }) {
    try {
      const { data } = yield call(
          axiosApi.auth().post,
          APIReplParams("/v1/e/exhibitor/:exhibitorId/foodstall-handler", {
            exhibitorId
          }),
          _.pickBy(form, _.identity)
        ),
        { success, err, handler } = data;
      if (success && !err) {
        yield put({
          type: FHANDLER_ACTION_SAVE_SUCCESS,
          licence: handler,
          exhibitorId
        });
      } else yield _spawnErr(err);
    } catch (e) {
      yield _spawnErr(e);
    }
  });
}

function* fhandlerUpdate() {
  yield takeLeading(FHANDLER_ACTION_UPDATE, function*({
    id,
    exhibitorId,
    form
  }) {
    try {
      const { data } = yield call(
          axiosApi.auth().put,
          `/v1/e/exhibitor/${exhibitorId}/foodstall-handler/${id}`,
          form
        ),
        { success, err } = data;
      if (success && !err) {
        yield put({ type: FHANDLER_ACTION_UPDATE_SUCCESS, exhibitorId, id });
        yield put({ type: FHANDLER_ACTION_FETCH, exhibitorId });
      } else yield _spawnErr(err);
    } catch (e) {
      yield _spawnErr(e);
    }
  });
}

function* fappFetch() {
  yield takeLeading(FAPP_ACTION_FETCH, function*({ exhibitorId, isNotClient }) {
    try {
      const { data } = yield call(
        axiosApi.auth().get,
        `/v1/e${
          isNotClient ? "/a" : ""
        }/exhibitor/${exhibitorId}/foodstall-handler-registration`
      );
      yield put({
        type: FAPP_ACTION_FETCH_SUCCESS,
        exhibitorId,
        licences: data || []
      });
    } catch (e) {
      yield _spawnErr(e);
    }
  });
}
function* fappSave() {
  yield takeLeading(FAPP_ACTION_SAVE, function*({
    eventId,
    exhibitorId,
    form,
    id,
    action
  }) {
    try {
      const { data } = action === "update"
      ? yield call(
        axiosApi.auth().put,
        `/v1/e/event/${eventId}/exhibitor/${exhibitorId}/foodstall-handler-registration/${id}`,
        _.pickBy(form, _.identity)
      )
      : yield call(
          axiosApi.auth().post,
          `/v1/e/event/${eventId}/exhibitor/${exhibitorId}/foodstall-handler-registration`,
          _.pickBy(form, _.identity)
        ),
        { success, registration, err } = data;
      if (success === true) {
        yield put({ type: FAPP_ACTION_SAVE_SUCCESS, licence: registration });
        // yield put({type:FAPP_ACTION_FETCH,exhibitorId})
      } else yield _spawnErr(err || "Unexpected error");
    } catch (e) {
      yield _spawnErr(e);
    }
  });
  yield takeLeading(FAPP_ACTION_SUBMIT, function*({
    eventId,
    exhibitorId,
    id
  }) {
    try {
      const { data } = yield call(
          axiosApi.auth().put,
          `/v1/e/event/${eventId}/exhibitor/${exhibitorId}/foodstall-handler-registration/${id}/submit`
        ),
        { err, error } = data;
      if (!err && !error) {
        yield put({
          type: FAPP_ACTION_SUBMIT_SUCCESS,
          eventId,
          exhibitorId,
          id
        });
      } else yield _spawnErr(err || error);
    } catch (e) {
      yield _spawnErr(e);
    }
  });
}

function* fappRemark() {
  yield takeEvery(FAPP_ACTION_REMARK, function*({ exhibitorId, id, remark }) {
    try {
      const { data } = yield call(
        axiosApi.auth().put,
        `/v1/e/a/foodstall-handler-registration/${id}/${remark}`
      );
      yield put({ type: FAPP_ACTION_REMARK_SUCCESS, id });
      yield put({ type: FAPP_ACTION_FETCH, exhibitorId, isNotClient: true });
    } catch (e) {
      yield _spawnErr(e);
    }
  });
}

export default function* licencesSaga() {
  yield takeEvery(FSTALL_ACTION_ERROR, function*({ error }) {
    console.log(JSON.stringify(error))
    yield put(
      NotiActionsEnqueueToast({
        message: (
          <div>
            {/* <h5>F&B Licences Error</h5> */}
            <span>{_.get(error, "message", "An entry is required or has an invalid value. Please correct and try again.")}</span>
          </div>
        ),
        options: { variant: "error", autoHideDuration: 2000 }
      })
    );
  });
  yield combineRootSagas(
    licencesFetch,
    licencesSave,
    licencesSubmit,
    licenceAdmin,
    fhandlerFetch,
    fhandlerPost,
    fhandlerUpdate,
    fappFetch,
    fappSave,
    fappRemark
  );
}
